import clsx from 'clsx';

import { PlusIcon } from '@heroicons/react/20/solid';

import type { HeroIcon } from '~/types';

import { Card } from './Layout';
import { Text } from './_legacy/Typography';

type CardButtonProps = {
  Icon: HeroIcon;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
};

export const CardButton = ({
  Icon,
  children,
  className,
  onClick,
  disabled = false,
}: CardButtonProps) => (
  <Card
    className={clsx(
      'relative rounded-sm !bg-neutral-0 !p-3 transition-all',
      className,
      {
        'cursor-not-allowed shadow-none': disabled,
        'hover:shadow-md': !disabled,
      },
    )}
  >
    <button
      className={clsx('flex items-center gap-3', {
        'pointer-events-none': disabled,
      })}
      onClick={onClick}
    >
      <div
        className={clsx(
          'rounded-sm border border-neutral-250 bg-neutral-50 p-4',
        )}
      >
        <Icon className={clsx('h-5 w-5 text-neutral-600')} />
      </div>
      <div className="flex flex-col !items-start justify-center !text-start">
        {children}
      </div>
    </button>
  </Card>
);

type CreateNewResourceButtonProps = {
  title: string;
  subtitle: string;
  className?: string;
  disabled?: boolean;
};

export const CreateNewResourceButton = ({
  title,
  subtitle,
  className,
  disabled = false,
}: CreateNewResourceButtonProps) => (
  <CardButton
    Icon={PlusIcon}
    className={clsx(
      '!rounded-md active:!border-accent-purple-200 active:!bg-neutral-250',
      {
        '!bg-neutral-100 !shadow-none': disabled,
      },
    )}
    disabled={disabled}
  >
    <div className={clsx('flex flex-col text-left', className)}>
      <Text
        size="normal"
        weight="medium"
        variant="heading"
        className={clsx('leading-[150%]', {
          'text-neutral-450': disabled,
        })}
      >
        {title}
      </Text>
      <Text
        className={clsx('leading-[150%]', {
          'text-neutral-400': disabled,
        })}
      >
        {subtitle}
      </Text>
    </div>
  </CardButton>
);
