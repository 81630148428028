import { forwardRef, useCallback, useMemo } from 'react';

import { Link, NavLink, useLocation } from '@remix-run/react';

import clsx from 'clsx';
import { tailwindConfig } from '@raffle-ai/design-system';

import {
  ChartBarSquareIcon,
  RectangleGroupIcon,
  CommandLineIcon,
  ArrowRightOnRectangleIcon,
  Cog6ToothIcon,
  UserCircleIcon,
  EllipsisVerticalIcon,
  ChevronRightIcon,
  CircleStackIcon,
  IdentificationIcon,
  CpuChipIcon,
  FlagIcon,
  QuestionMarkCircleIcon,
  DocumentTextIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline';
import { HomeIcon } from '@heroicons/react/24/solid';
import { ArrowPathIcon } from '@heroicons/react/20/solid';

import type { HeroIcon } from '~/types';
import type { Role } from '~/models';

import { useSidebarSetter, useSidebarState, useUser } from '~/providers';

import {
  hasRolePermission,
  isRaffleAdmin,
  parseRole,
  rolesDict,
  routePermissions,
} from '~/utils';
import { AccountSwitcherPopup } from '~/components/AccountSwitcher/Popup';

import { Popup, PopupMenu, PopupMenuItem } from '../../Popup';
import { Text } from '../../_legacy/Typography';
import { RaffleLogoText } from '../../RaffleLogo';
import { SidebarCardButton } from './SidebarCardButton';

type SidebarProps = {
  isMobile: boolean;
};

const Sidebar = ({ isMobile }: SidebarProps) => {
  const { search } = useLocation();
  const { user, account } = useUser();
  const { minimise, show } = useSidebarState();
  const setSidebarState = useSidebarSetter();

  const handleLinkClick = useCallback(() => {
    isMobile && setSidebarState({ minimise, show: false });
  }, [isMobile, minimise, setSidebarState]);

  const isAdmin = useMemo(() => isRaffleAdmin(user.roles), [user.roles]);
  const minimizeBtnColor = useMemo(
    () => (minimise ? '#99A5B9' : '#ffffff'),
    [minimise],
  );

  const isTrial = account.features.trial_access?.amount === 1;

  return (
    <div
      className={clsx(
        'duration-[250ms] relative z-30 box-border flex h-full w-full flex-col items-center justify-between bg-dark px-5 pb-8 pt-2 shadow transition-[width] ease-out',
        { '!py-8 md:w-24': minimise, 'md:w-56': !minimise },
      )}
    >
      <div
        className={clsx('mb-8 flex w-full items-center justify-between', {
          '!justify-center': minimise,
        })}
      >
        {!minimise && (
          <Link
            to="/"
            className={clsx(
              'flex h-16 w-16 items-center justify-start self-start p-0.5',
              { 'mx-auto': minimise },
            )}
            onClick={handleLinkClick}
          >
            <RaffleLogoText white />
          </Link>
        )}

        {!isMobile && (
          <button
            onClick={() => setSidebarState({ minimise: !minimise, show })}
          >
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="4"
                y="5.5"
                width="17"
                height="13"
                rx="1"
                stroke={minimizeBtnColor}
              />
              <path d="M9 5.5V18.5" stroke={minimizeBtnColor} />
              <path d="M5.5 8L7.5 8" stroke={minimizeBtnColor} />
              <path d="M5.5 10L7.5 10" stroke={minimizeBtnColor} />
              <path d="M5.5 12L7.5 12" stroke={minimizeBtnColor} />
            </svg>
          </button>
        )}
      </div>

      <div className="flex w-full flex-1 flex-col items-center gap-4 overflow-auto">
        <div
          className={clsx('flex w-full flex-col gap-1.5', {
            'items-center !gap-2': minimise,
          })}
        >
          <Text size="normal" weight="medium" className="mb-2.5 text-grey-dark">
            Menu
          </Text>

          {/* Remove this constraint once we implement the homepage for all the users */}
          {isTrial && (
            <SidebarButton
              Icon={HomeIcon}
              label="Home"
              to={`/home`}
              onClick={handleLinkClick}
              roles={routePermissions.insights}
            />
          )}

          <SidebarButton
            Icon={ChartBarSquareIcon}
            label="Insights"
            to={`/insights/dashboard${search}`}
            onClick={handleLinkClick}
            roles={routePermissions.insights}
          />

          <SidebarButton
            Icon={RectangleGroupIcon}
            label={isTrial ? 'Your widget' : 'Search UIs'}
            to="/tools"
            onClick={handleLinkClick}
            roles={routePermissions.tools}
          />

          <SidebarButton
            Icon={CpuChipIcon}
            label="Indexes"
            to="/sources"
            onClick={handleLinkClick}
            roles={routePermissions.sources}
          />

          <SidebarButton
            Icon={ArrowPathIcon}
            label="Re-rank"
            to="/rerank/overview"
            onClick={handleLinkClick}
            roles={routePermissions.sources}
          />

          {!isTrial && (
            <SidebarButton
              Icon={CircleStackIcon}
              label="API"
              to="/api/api_keys"
              onClick={handleLinkClick}
              roles={routePermissions.api}
            />
          )}
        </div>

        {isAdmin && (
          <div
            className={clsx('flex w-full flex-col gap-1.5', {
              'items-center !gap-2': minimise,
            })}
          >
            <Text
              size="normal"
              weight="medium"
              className="mb-2.5 text-grey-dark"
            >
              {minimise ? 'Backst.' : 'Backstage'}
            </Text>

            <SidebarButton
              Icon={IdentificationIcon}
              label="Plan"
              to="/backstage/plan"
              onClick={handleLinkClick}
              roles={routePermissions.backstage}
            />

            <SidebarButton
              Icon={RectangleGroupIcon}
              label="Tools"
              to="/backstage/tools"
              onClick={handleLinkClick}
              roles={routePermissions.backstage}
            />

            <SidebarButton
              Icon={CpuChipIcon}
              label="Indexes"
              to="/backstage/sources"
              onClick={handleLinkClick}
              roles={routePermissions.backstage}
            />

            <SidebarButton
              Icon={CommandLineIcon}
              label="Models"
              to="/backstage/models"
              onClick={handleLinkClick}
              roles={routePermissions.backstage}
            />
          </div>
        )}
      </div>

      <div className="flex w-full flex-col items-center gap-4">
        {account.features.consumption_based && <CreditsCounter />}

        <LinksButton minimise={minimise} isTrial={isTrial} />

        {isAdmin && <AccountSwitcherPopup />}

        <Popup
          fallback={<UserButton />}
          trigger={<UserButton />}
          position="right bottom"
          arrow={false}
        >
          {/* eslint-disable-next-line */}
          {/* @ts-ignore */}
          {(close: MouseEventHandler<HTMLButtonElement> | undefined) => (
            <PopupMenu
              origin="bottom-left"
              className="ml-2 flex flex-col gap-1 rounded-md bg-primary-550 p-1 shadow-md"
            >
              <SettingsButton onClick={close} />
              <Link to="/logout">
                <PopupMenuItem
                  Icon={ArrowRightOnRectangleIcon}
                  className="!border-none !bg-primary-550 !text-neutral-50 hover:!bg-accent-purple-300"
                >
                  <Text className="!text-inherit">Log out</Text>
                </PopupMenuItem>
              </Link>
            </PopupMenu>
          )}
        </Popup>
      </div>
    </div>
  );
};

type SidebarButtonProps = {
  Icon: HeroIcon;
  label: string;
  to: string;
  className?: string;
  roles?: Role[];
  onClick: () => void;
  hide?: boolean;
  noLines?: boolean;
};

const SidebarButton = ({
  Icon,
  label,
  to,
  className,
  roles = [],
  onClick,
  hide = false,
  noLines = false,
}: SidebarButtonProps) => {
  const { user } = useUser();
  const { minimise } = useSidebarState();

  const isAllowed = useMemo(
    () => hasRolePermission({ roles, userRoles: user.roles }),
    [roles, user.roles],
  );

  const ButtonComponent = useMemo(
    () => (
      <NavLink
        to={to}
        onClick={isAllowed ? onClick : (e) => e.preventDefault()}
        className={({ isActive, isPending }) =>
          clsx(
            'duration-[250ms] flex w-full items-center gap-2.5 overflow-hidden rounded-sm bg-right px-2 py-1 transition-all ease-out',
            {
              'text-grey-dark hover:text-white-off': !isActive && isAllowed,
              'rounded-sm !bg-[length:200%_100%] !bg-left text-raffle-blue-dark':
                isActive,
              activeBackground: isActive,
              'cursor-not-allowed border-none text-grey': !isAllowed,
              'pointer-events-none border-grey-dark/40': isPending,
              '!p-2': minimise,
            },
            className,
          )
        }
      >
        <Icon
          className={clsx('z-10 h-4 w-4 text-inherit', { 'h-5 w-5': minimise })}
        />

        {!minimise && (
          <>
            <Text size="base" className="z-10 !text-inherit">
              {label}
            </Text>
            {label === 'API' && (
              <Text
                size="sm"
                className="rounded-sm bg-accent-purple-400 px-1 py-0.5 !text-white"
              >
                Dev
              </Text>
            )}
          </>
        )}
      </NavLink>
    ),
    [to, isAllowed, onClick, Icon, minimise, label, className],
  );

  if (!isAllowed && hide) {
    return null;
  }

  return (
    <div
      className={clsx('flex', {
        relative: !noLines && !minimise,
        'w-full': !minimise,
        'w-min': minimise,
      })}
    >
      <Popup
        on="hover"
        disabled={!minimise}
        position="right center"
        trigger={ButtonComponent}
        fallback={ButtonComponent}
        arrowStyle={{ color: tailwindConfig.theme.colors.primary[450] }}
        offsetX={5}
        contentStyle={{
          boxShadow:
            'rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(2, 76, 100, 0.1) 0px 0px 10px 0px',
        }}
      >
        <div className="rounded-md bg-dark-accent p-3">
          <Text className="!text-grey-light">{label}</Text>
        </div>
      </Popup>
    </div>
  );
};

const UserButton = forwardRef<HTMLButtonElement>((props, ref) => {
  const { user } = useUser();
  const { minimise } = useSidebarState();

  return (
    <SidebarCardButton ref={ref} {...props}>
      <UserCircleIcon className="h-5 w-5 text-white-off" />

      {!minimise && (
        <>
          <div className="flex w-20 flex-col text-left">
            <Text className="truncate !text-white-off">
              {user.full_name.split(' ')[0]}
            </Text>

            <Text size="sm" className="!text-neutral-350">
              {rolesDict[parseRole(user.roles)]}
            </Text>
          </div>

          <EllipsisVerticalIcon className="h-4 text-neutral-0" />
        </>
      )}
    </SidebarCardButton>
  );
});

UserButton.displayName = 'UserButton';

type SettingsButtonProps = { onClick: () => void };
const SettingsButton = ({ onClick }: SettingsButtonProps) => {
  const { user } = useUser();

  const isAllowed = useMemo(
    () =>
      hasRolePermission({
        roles: routePermissions.settings,
        userRoles: user.roles,
      }),
    [user.roles],
  );

  if (!isAllowed) {
    return null;
  }

  return (
    <Link onClick={onClick} to="/settings">
      <PopupMenuItem
        Icon={Cog6ToothIcon}
        className="!border-none !bg-primary-550 !text-neutral-50 hover:!bg-accent-purple-300"
      >
        <Text className="!text-inherit">Settings</Text>
      </PopupMenuItem>
    </Link>
  );
};

type LinksButtonType = {
  minimise: boolean;
  isTrial: boolean;
};
const LinksButton = ({ minimise, isTrial }: LinksButtonType) => {
  const triggerButton = useMemo(
    () => (
      <SidebarCardButton>
        <QuestionMarkCircleIcon className="h-5 w-5 text-inherit text-neutral-0" />
        {!minimise && (
          <>
            <div className="flex w-20 flex-col text-left">
              <Text className="!text-white-off">Help</Text>
            </div>

            <ChevronRightIcon className="h-4 text-white-off" />
          </>
        )}
      </SidebarCardButton>
    ),
    [minimise],
  );

  return (
    <Popup
      fallback={triggerButton}
      trigger={triggerButton}
      position="right top"
      arrow={false}
    >
      <PopupMenu
        origin="top-left"
        className="shadow-m ml-2 flex flex-col gap-1 rounded-md bg-primary-550 p-1"
      >
        <PopupMenuItem
          Icon={FlagIcon}
          className="!border-none !bg-primary-550 !text-neutral-50 hover:!bg-accent-purple-300"
        >
          <a href="https://status.raffle.ai/" target="_blank" rel="noreferrer">
            <Text className="!text-inherit">Status Page</Text>
          </a>
        </PopupMenuItem>

        <PopupMenuItem
          Icon={DocumentTextIcon}
          className="!border-none !bg-primary-550 !text-neutral-50 hover:!bg-accent-purple-300"
        >
          <a
            href="https://raffle.ai/docs/home"
            target="_blank"
            rel="noreferrer"
          >
            <Text className="!text-inherit">Documentation</Text>
          </a>
        </PopupMenuItem>

        <PopupMenuItem
          Icon={PhoneIcon}
          className="!border-none !bg-primary-550 !text-neutral-50 hover:!bg-accent-purple-300"
        >
          <a
            href={
              isTrial ? 'mailto:hello@raffle.ai' : 'mailto:support@raffle.ai'
            }
            target="_blank"
            rel="noreferrer"
          >
            <Text className="!text-inherit">Contact Us</Text>
          </a>
        </PopupMenuItem>
      </PopupMenu>
    </Popup>
  );
};

const CreditsCounter = () => {
  const {
    account: { credits },
  } = useUser();

  const { minimise } = useSidebarState();

  return (
    <div
      className={clsx(
        'flex w-full flex-col  gap-1 rounded-md border border-primary-450 px-3 py-2',
        {
          'items-center !p-1': minimise,
        },
      )}
    >
      {!minimise && (
        <p className="text-base font-medium leading-normal !text-neutral-400">
          Credits
        </p>
      )}

      <div className="flex items-end gap-1">
        <p
          className={clsx('font-bold leading-normal !text-neutral-0', {
            'text-sm': minimise,
            'text-base': !minimise,
          })}
        >
          {credits}
        </p>
      </div>
    </div>
  );
};

export default Sidebar;
