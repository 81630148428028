import { useState, useEffect } from 'react';

import clsx from 'clsx';

import type { HeroIcon } from '~/types';

import { Text } from './_legacy/Typography';

type valueOption = {
  label: string;
  value: string;
  Icon?: HeroIcon;
  iconClassName?: string;
};

type ToggleProps = {
  className?: string;
  falseOption: valueOption;
  trueOption: valueOption;
  defaultValue: string;
  value?: string;
  onClick: (val: string) => void;
  disabled?: boolean;
  wrapperClassName?: string;
};

const RichToggle = ({
  className,
  falseOption,
  trueOption,
  defaultValue,
  value: controlledValue,
  onClick,
  disabled = false,
  wrapperClassName,
}: ToggleProps) => {
  const [value, setValue] = useState<string>(() => defaultValue);

  const handleClick = () => {
    setValue(() =>
      value === trueOption.value ? falseOption.value : trueOption.value,
    );
    onClick(value === trueOption.value ? falseOption.value : trueOption.value);
  };

  const { Icon: TrueIcon } = trueOption;
  const { Icon: FalseIcon } = falseOption;

  useEffect(() => {
    if (typeof controlledValue !== 'undefined') {
      setValue(controlledValue);
    }
  }, [controlledValue]);

  return (
    <div
      className={clsx('rounded-md bg-neutral-150 p-0.5', wrapperClassName, {
        'cursor-not-allowed opacity-60 ': disabled,
      })}
    >
      <button
        type="button"
        onClick={handleClick}
        className={clsx(
          'relative flex w-full items-center rounded-sm bg-neutral-150 py-1',
          className,
        )}
        disabled={disabled}
      >
        <OptionLabel
          label={falseOption.label}
          Icon={FalseIcon}
          disabled={disabled}
        />

        <OptionLabel
          label={trueOption.label}
          Icon={TrueIcon}
          disabled={disabled}
        />

        <label
          className={clsx(
            'delay-50 absolute top-0 flex h-full w-1/2 items-center justify-center rounded-md border border-grey-light bg-white text-center shadow transition ease-in-out',
            {
              'translate-x-full': value === trueOption.value,
              'cursor-not-allowed': disabled,
              'cursor-pointer': !disabled,
            },
          )}
        >
          {value === trueOption.value && TrueIcon && (
            <TrueIcon
              className={clsx(
                'h-4 w-4 text-neutral-500',
                trueOption.iconClassName,
              )}
            />
          )}
          {value === falseOption.value && FalseIcon && (
            <FalseIcon
              className={clsx(
                'h-4 w-4 text-neutral-500',
                falseOption.iconClassName,
              )}
            />
          )}
          <Text
            size="base"
            weight="medium"
            className={clsx('leading-6 text-neutral-500', {
              'ml-2': TrueIcon || FalseIcon,
            })}
          >
            {value === trueOption.value ? trueOption.label : falseOption.label}
          </Text>
        </label>
      </button>
    </div>
  );
};

type OptionProps = {
  label: string;
  Icon?: HeroIcon;
  disabled: boolean;
};

const OptionLabel = ({ label, Icon, disabled }: OptionProps) => (
  <label
    className={clsx('flex w-1/2 items-center justify-center text-center', {
      'cursor-pointer': !disabled,
      'cursor-not-allowed': disabled,
    })}
  >
    {Icon && <Icon className="h-4 w-4 text-neutral-400" />}
    <Text
      variant="faded"
      size="base"
      className={clsx('leading-6', { 'ml-2': Icon })}
    >
      {label}
    </Text>
  </label>
);

export default RichToggle;
