import { format } from '~/utils';

import { Text } from '../_legacy/Typography';
import { PressToCopyText } from './PressToCopyText';

type TimestampTextProps = {
  isBackstage?: boolean;
  children: string;
};

export const TimestampText = ({
  isBackstage = false,
  children,
}: TimestampTextProps) => {
  const date =
    children === null
      ? 'Not synced'
      : format(children, `dd MMMM yyyy ${isBackstage ? 'HH:mm' : ''}`);

  if (isBackstage) {
    return <PressToCopyText copyText={children}>{date}</PressToCopyText>;
  }

  return <Text className="cursor-default">{date}</Text>;
};
