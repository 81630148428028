import { Link } from '@remix-run/react';

import clsx from 'clsx';

import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/20/solid';

import type { Source } from '~/models';

import { sourceTypeDict, webscrapeCMS } from '~/utils';

import { Button, Checkbox, Chip, DynamicTooltip, Text } from '~/components';

export type SourceCardProps = {
  source: {
    id: Source['id'];
    type: Source['type'];
    title: Source['title'];
    internal: Source['internal'];
    subtype?: Source['subtype'];
  };
  displayViewContent?: boolean;
  selected: boolean;
  disabled: boolean;
  onToggle: () => void;
};

const SourceCard = ({
  source: { id, title, internal, type, subtype },
  displayViewContent = false,
  selected,
  disabled,
  onToggle,
}: SourceCardProps) => {
  const { img, Icon, name } =
    subtype && webscrapeCMS.includes(subtype)
      ? {
          img: sourceTypeDict[subtype].imgUrl,
          Icon: undefined,
          name: sourceTypeDict[subtype].name,
        }
      : {
          img: sourceTypeDict[type].imgUrl,
          Icon: sourceTypeDict[type].Icon,
          name: sourceTypeDict[type].name,
        };

  return (
    <div className="group relative flex items-center justify-between border-b border-neutral-250 bg-neutral-50 px-4 py-3 transition-background first:rounded-t-md last:rounded-b-md last:!border-none hover:bg-neutral-100">
      <div className="flex max-w-[60%] items-center gap-2 lg:gap-4">
        <DynamicTooltip
          offset={{ x: 0, y: -30 }}
          content={
            <Text size="sm" className="!text-neutral-600">
              {name}
            </Text>
          }
        >
          <div className="max-h-12 rounded-sm border border-neutral-250 bg-neutral-100 p-2">
            {img && <img className="h-[30px] w-[30px]" src={img} alt={type} />}

            {Icon && <Icon className="h-[30px] w-[30px] stroke-1" />}
          </div>
        </DynamicTooltip>
        <DynamicTooltip
          offset={{ x: 0, y: -30 }}
          content={
            <Text size="sm" className="!text-neutral-600">
              {title}
            </Text>
          }
        >
          <Text
            weight="medium"
            className={clsx(
              'max-w-[75%] cursor-default truncate !text-primary-500',
              {
                '!text-neutral-350': disabled,
              },
            )}
          >
            {title}
          </Text>
        </DynamicTooltip>

        <Chip
          textClassName="text-xs leading-normal"
          Icon={internal ? LockClosedIcon : LockOpenIcon}
        >
          {internal ? 'Private' : 'Public'}
        </Chip>
      </div>

      <div className="flex items-center justify-end gap-4">
        {displayViewContent && (
          <Link to={`/sources/${id}/answers`}>
            <Button className="truncate bg-neutral-50 !px-3 !py-1 !shadow-none">
              View content
            </Button>
          </Link>
        )}

        <label htmlFor={`source-${id}`}>
          <Checkbox
            ref={undefined}
            name={`source-${id}`}
            id={`source-${id}`}
            onChange={onToggle}
            checked={disabled ? false : selected}
            disabled={disabled}
          />
        </label>
      </div>

      {disabled && (
        <div className="invisible absolute right-4 cursor-default rounded-sm border border-secondary-400 bg-secondary-300 px-3 py-2 group-hover:visible">
          <Text variant="white">
            You can&apos;t add a private data source to a public Search UI
          </Text>
        </div>
      )}
    </div>
  );
};

export default SourceCard;
