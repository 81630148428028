import { useMemo } from 'react';

import {
  Form,
  Link,
  useLocation,
  useNavigation,
  useOutletContext,
  useSearchParams,
  useSubmit,
} from '@remix-run/react';

import clsx from 'clsx';
import { confirmAlert } from 'react-confirm-alert';

import {
  DocumentTextIcon,
  QuestionMarkCircleIcon,
  PencilSquareIcon,
  NoSymbolIcon,
  TrashIcon,
  CheckCircleIcon,
} from '@heroicons/react/20/solid';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';

import type { ColumnDef } from '@tanstack/react-table';

import type { AnswerPartial, AnswersResponse, Source } from '~/models';

import { useUser } from '~/providers';

import { fileTypesImg, inferExcludeOption, isRaffleAdmin } from '~/utils';

import type { TableProps } from '~/components';
import {
  ButtonTooltip,
  Card,
  DeleteConfirmModal,
  Table,
  TableHeader as Header,
  Text,
  PressToCopyText,
  TimestampText,
  CONFIRM_MODAL_OVERLAY_CLASSNAME,
  Button,
  TableTopbar,
  LoadingSpinner,
  Pagination,
  Input,
  SourceOptions,
  Chip,
  Popup,
  PopupMenu,
} from '~/components';

import { TableBottombar } from './TableBottombar';

const PAGINATION_FETCH_LIMIT = 200;

const columns: Array<ColumnDef<AnswerPartial>> = [
  {
    id: 'id',
    accessorKey: 'id',
    header: () => <Header>ID</Header>,
    cell: ({ getValue }) => (
      <Chip className="!px-2">
        <PressToCopyText>{getValue<number>()}</PressToCopyText>
      </Chip>
    ),
  },
  {
    id: 'document',
    header: () => <Header>Document</Header>,
    cell: ({ row: { original } }) => (
      <DocumentCell fileName={original.external_source_id} />
    ),
  },
  {
    id: 'content',
    accessorKey: 'content',
    header: () => <Header>Content</Header>,
    cell: ({ getValue }) => (
      <div
        dangerouslySetInnerHTML={{ __html: getValue<string>() }}
        className="h-5 overflow-hidden"
      />
    ),
  },
  {
    id: 'source_url',
    accessorKey: 'source_url',
    header: () => <Header>URL</Header>,
    cell: ({ getValue }) => (
      <PressToCopyText toastMessage="URL">{getValue<string>()}</PressToCopyText>
    ),
  },
  {
    id: 'title',
    accessorKey: 'title',
    header: () => <Header>Title</Header>,
    cell: ({ getValue }) => <Text>{getValue<string>()}</Text>,
  },
  {
    id: 'created_at',
    accessorKey: 'created_at',
    header: () => <Header>Created</Header>,
    cell: ({ getValue }) => (
      <TimestampText isBackstage>{getValue<string>()}</TimestampText>
    ),
  },
  {
    id: 'updated_at',
    accessorKey: 'updated_at',
    header: () => <Header>Updated</Header>,
    cell: ({ getValue }) => (
      <TimestampText isBackstage>{getValue<string>()}</TimestampText>
    ),
  },
  {
    id: 'options',
    header: () => <Header>Options</Header>,
    cell: ({ row: { original } }) => <Options original={original} />,
  },
];

const Options = ({ original }: { original: AnswerPartial }) => {
  const { source } = useOutletContext<AnswersOutletData>();
  const { pathname, search } = useLocation();
  const submit = useSubmit();

  const {
    user: { roles },
    account: { features },
  } = useUser();

  const isAdmin = isRaffleAdmin(roles);
  const isBackstage = useMemo(() => pathname.includes('backstage'), [pathname]);

  const isExcluded =
    inferExcludeOption(source as Source, original.external_source_id) !==
    'include';

  const id =
    source.type === 'instant_answers'
      ? original.external_source_id
      : original.id;

  if (source.type === 'instant_answers') {
    return (
      <div className="flex items-center gap-3">
        <Link to={`${id}${search}`}>
          <Button Icon={PencilSquareIcon}>Edit</Button>
        </Link>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-end gap-3">
      <Link
        to={`${id}/questions${search}`}
        className={clsx({
          'pointer-events-none': !features.keyword_boosting && !isAdmin,
        })}
      >
        <ButtonTooltip
          Icon={QuestionMarkCircleIcon}
          disabled={!features.keyword_boosting && !isAdmin}
          variant="secondary"
        >
          Add questions
        </ButtonTooltip>
      </Link>

      <Link to={`${id}${search}`}>
        <ButtonTooltip
          Icon={DocumentTextIcon}
          type="button"
          variant="secondary"
        >
          View
        </ButtonTooltip>
      </Link>

      {source.type === 'web_scrape' && isBackstage && (
        <Link to={`${id}/exclude${search}`}>
          <ButtonTooltip
            Icon={isExcluded ? NoSymbolIcon : CheckCircleIcon}
            variant="secondary"
          >
            Exclude
          </ButtonTooltip>
        </Link>
      )}

      {source.type === 'manual_upload' && (
        <Form
          onSubmit={(e) => {
            e.preventDefault();

            confirmAlert({
              overlayClassName: CONFIRM_MODAL_OVERLAY_CLASSNAME,
              customUI: ({ onClose }) => (
                <DeleteConfirmModal
                  resource={`answer "${original.title}"`}
                  onClose={onClose}
                  onAccept={() =>
                    submit(null, {
                      method: 'delete',
                      action: `${pathname}/${id}`,
                    })
                  }
                />
              ),
            });
          }}
        >
          <ButtonTooltip type="submit" Icon={TrashIcon} variant="secondary">
            Delete
          </ButtonTooltip>
        </Form>
      )}
    </div>
  );
};

export type AnswersTableProps = Omit<TableProps<AnswerPartial>, 'columns'> & {
  isBackstage?: boolean;
  pagination: AnswersResponse['pagination'];
};

export type AnswersOutletData = {
  source: {
    id: Source['id'];
    type: Source['type'];
    configuration: Source['configuration'];
    title?: Source['title'];
    knowledge_base_id: Source['knowledge_base_id'];
  };
};

export const AnswersTable = ({
  data,
  isBackstage = false,
  pagination,
}: AnswersTableProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigation = useNavigation();
  const { pathname } = useLocation();

  const { source } = useOutletContext<AnswersOutletData>();

  const filter = searchParams.get('filter') || '';

  const handlePageChange = (page: number) => {
    const offset = (page - 1) * PAGINATION_FETCH_LIMIT;

    if (offset < 0 || offset >= pagination.count) {
      return;
    }

    setSearchParams({
      offset: offset.toString(),
      ...(filter.length > 0 && {
        filter,
        knowledge_base_id: source.knowledge_base_id.toString(),
      }),
    });
  };

  const hiddenColumns = {
    content: false,
    document: false,
    ...(!isBackstage && {
      id: false,
    }),
    ...(source.type === 'instant_answers' && {
      title: false,
      content: true,
    }),
    ...(source.type === 'manual_upload' && {
      title: false,
      document: true,
      source_url: false,
    }),
  };

  return (
    <>
      <TableTopbar className="-mb-6">
        <Form method="get" replace className="w-1/3" action={pathname}>
          <Input
            type="search"
            id="search-source-db"
            placeholder={`Filter index database by${
              isBackstage ? ' id,' : ''
            } source URL or title`}
            name="filter"
            defaultValue={filter}
            onChange={(e) => {
              if (e.target.value.length === 0) {
                setSearchParams({});
              }
            }}
            className="h-8 !border-neutral-250 !bg-neutral-0"
          />
          <Input
            name="knowledge_base_id"
            defaultValue={source.knowledge_base_id}
            id="search-source-db-kbId"
            hidden
          />
        </Form>

        <Popup
          trigger={
            <Button
              Icon={EllipsisVerticalIcon}
              iconClassName="h-5 w-5"
              variant="icon"
            />
          }
          position="left top"
          arrow={false}
        >
          <PopupMenu className="border border-neutral-250 bg-neutral-0 p-2">
            <SourceOptions source={source} answersView />
          </PopupMenu>
        </Popup>
      </TableTopbar>
      <Card className="z-0 overflow-auto !rounded-none !border-neutral-250 !p-0 shadow-none">
        <Table
          data={data}
          columns={columns}
          className="border-collapse"
          headerClassName="bg-white-off sticky top-0 z-10"
          headerCellClassName="text-left"
          bodyClassName="divide-y divide-grey-light"
          bodyRowClassName="hover:bg-white-off"
          hiddenColumns={hiddenColumns}
        />
      </Card>
      {data && data?.length > 0 && (
        <TableBottombar className="-mt-6">
          <Pagination
            page={pagination.offset / PAGINATION_FETCH_LIMIT + 1}
            pages={Math.floor(pagination.count / PAGINATION_FETCH_LIMIT) + 1}
            entries={pagination.count}
            onPageChange={(page) => handlePageChange(page)}
            disabledPrev={pagination.offset < PAGINATION_FETCH_LIMIT}
            disabledNext={
              pagination.offset + PAGINATION_FETCH_LIMIT >= pagination.count
            }
            loading={navigation.state === 'loading'}
          />

          {navigation.state === 'loading' && (
            <LoadingSpinner className="h-6 w-6 !p-0" />
          )}
        </TableBottombar>
      )}
    </>
  );
};

type DocumentCellProps = {
  fileName: string;
};

const DocumentCell = ({ fileName }: DocumentCellProps) => {
  const extension = fileName.includes('.')
    ? (fileName.split('.').pop() as keyof typeof fileTypesImg)
    : undefined;

  const title = useMemo(
    () => fileName.replace(`.${extension}`, ''),
    [fileName, extension],
  );

  return (
    <div className="flex items-center gap-3">
      <div className="rounded-sm border border-neutral-200 bg-neutral-100 p-2">
        {extension ? (
          <img
            className="h-7 w-7"
            src={fileTypesImg[extension]}
            alt={fileName}
          />
        ) : (
          <DocumentTextIcon className="h-6 w-6 text-neutral-600" />
        )}
      </div>
      <div className="flex items-center">
        <Text weight="medium" variant="heading">
          {extension ? title : fileName}
        </Text>
      </div>
    </div>
  );
};
