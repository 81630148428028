import { useCallback } from 'react';
import type { HTMLInputTypeAttribute } from 'react';

import { useOutletContext } from '@remix-run/react';

import _get from 'lodash.get';
import _set from 'lodash.set';
import translations from '@raffle-ai/ui-label-translations';
import clsx from 'clsx';

import type { Language } from '@raffle-ai/ui-label-translations/lib/types';
import type { OutletContext } from '~/routes/_app.tools_.$toolId/view';

import type { WidgetInstance } from '~/models';

import { useKnowledgeBase } from '~/providers';

import { Field } from '../Form';
import { iframeRestoreBackground } from '../Iframe.client';
import { Text } from '../_legacy/Typography';

type ToolTranslationFieldProps = {
  label: string;
  name: keyof Language;
  limit?: number;
  onFocus?: (e: React.FocusEvent<HTMLElement>) => void;
  onBlur?: () => void;
  type?: HTMLInputTypeAttribute;
  description?: string;
  noCard?: boolean;
  disabled?: boolean;
};
export const ToolTranslationField = ({
  label,
  name,
  limit,
  onFocus,
  onBlur,
  type,
  description,
  noCard = false,
  disabled = false,
}: ToolTranslationFieldProps) => {
  const { currentKnowledgeBase } = useKnowledgeBase();
  const translation =
    //@ts-ignore
    currentKnowledgeBase?.language !== 'undefined' &&
    currentKnowledgeBase?.language &&
    translations[currentKnowledgeBase?.language]
      ? translations[currentKnowledgeBase?.language]
      : translations.en;

  const { tool, onChangeTool } =
    useOutletContext<OutletContext<WidgetInstance>>();

  const handleChange = useCallback(
    (value: string) => {
      const overrides = { ...tool.configuration.text_overrides };
      _set(overrides, name, value);
      onChangeTool({
        'configuration.text_overrides': overrides,
      });
    },
    [name, onChangeTool, tool.configuration.text_overrides],
  );

  const value = _get(tool.configuration.text_overrides, name, '');

  return (
    <div
      className={clsx('flex w-full flex-col', {
        'rounded-sm border border-neutral-250 shadow-drop': !noCard,
      })}
    >
      {!noCard && (
        <div className="flex flex-col rounded-t-sm border-b border-neutral-250 bg-neutral-100 px-4 py-3">
          <Text weight="semibold" variant="heading" className="leading-[150%]">
            {label}
          </Text>
          <Text className="leading-[150%]">{description}</Text>
        </div>
      )}

      <div className={clsx({ 'p-4': !noCard })}>
        <Field
          onChange={(e) => handleChange(e.target.value)}
          value={value}
          placeholder={translation[name]}
          limit={limit}
          onFocus={onFocus}
          onBlur={() => {
            iframeRestoreBackground();
            onBlur?.();
          }}
          {...(type && { type: type })}
          {...(noCard ? { label } : { label: '' })}
          {...(noCard ? { description } : { description: '' })}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
