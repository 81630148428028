import { useFetcher } from 'react-router-dom';

import { CheckCircleIcon } from '@heroicons/react/20/solid';

import { useKnowledgeBase } from '~/providers';

import Button from './Button';

export const UnlockProductButton = () => {
  const fetcher = useFetcher();
  const { currentKnowledgeBase } = useKnowledgeBase();

  const { unlockRequested } =
    currentKnowledgeBase?.dashboard_configuration?.trial ?? {};

  return (
    <Button
      size="sm"
      variant="primary"
      className="w-fit !py-1"
      onClick={() =>
        fetcher.submit(
          { subaction: 'unlock' },
          { method: 'post', action: '/action/trial' },
        )
      }
      Icon={unlockRequested ? CheckCircleIcon : undefined}
      iconClassName="text-accent-green-400"
      disabled={unlockRequested}
    >
      {unlockRequested ? 'Upgrade requested' : 'Unlock the full product'}
    </Button>
  );
};
