import { Link, useNavigate } from '@remix-run/react';

import { EnvelopeIcon } from '@heroicons/react/24/solid';

import Button from '../Button';
import { Input } from '../Form';
import RichToggle from '../RichToggle';
import PageFilters from './PageFilters';

type PageFiltersSettigsProps = {
  route: 'users' | 'pending-invites';
  query: string;
  onChangeQuery: (query: string) => void;
};

const PageFiltersSettigs = ({
  route,
  query,
  onChangeQuery,
}: PageFiltersSettigsProps) => {
  const navigate = useNavigate();

  const isUsersRoute = route === 'users';

  return (
    <PageFilters className="mb-8">
      <Link to="invite">
        <Button variant="primary" Icon={EnvelopeIcon}>
          Invite new users
        </Button>
      </Link>

      <div className="flex flex-1 items-center justify-end gap-8">
        <Input
          id="search-users-table"
          type="search"
          containerClassName="w-1/3"
          value={query}
          onChange={(e) => onChangeQuery(e.target.value)}
          onClearSearch={() => onChangeQuery('')}
          placeholder={
            isUsersRoute
              ? 'Filter by user, roles, teams or last active'
              : 'Filter by email, sent or expiration date'
          }
        />

        <RichToggle
          className="!w-96"
          falseOption={{ label: 'Users', value: 'users' }}
          trueOption={{ label: 'Pending Invites', value: 'pending-invites' }}
          defaultValue={route}
          onClick={() =>
            navigate(`/settings/users${isUsersRoute ? '/pending-invites' : ''}`)
          }
        />
      </div>
    </PageFilters>
  );
};

export default PageFiltersSettigs;
