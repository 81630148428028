import { Popup, PopupMenu } from '../Popup';
import { AccountButton } from './AccountButton';
import AccountSwitcher from './AccountSwitcher';

const overlayStyle = {
  backgroundColor: 'rgb(18 21 31 / 0.2)',
};

export function AccountSwitcherPopup() {
  return (
    <Popup
      trigger={<AccountButton />}
      fallback={<AccountButton />}
      position="right bottom"
      arrow={false}
      {...{ overlayStyle }}
    >
      {/* eslint-disable-next-line */}
      {/* @ts-ignore */}
      {(close: MouseEventHandler<HTMLButtonElement> | undefined) => (
        <PopupMenu origin="bottom-left" className="ml-12">
          <AccountSwitcher close={close} />
        </PopupMenu>
      )}
    </Popup>
  );
}
