const ParticlesAnimation = () => (
  <div className="absolute h-full w-full flex-col items-center justify-between">

<div className='wrap'>
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
  <div className='c' />
</div>


  </div>
);

export default ParticlesAnimation;
