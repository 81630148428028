import React, { useCallback, useMemo } from 'react';

import {
  NavLink,
  useLocation,
  useMatches,
  useSearchParams,
} from '@remix-run/react';

import clsx from 'clsx';

import { ChevronRightIcon, Bars3Icon } from '@heroicons/react/24/solid';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

import type { Instance } from '~/models';
import type { HeroIcon } from '~/types';

import {
  useKnowledgeBase,
  useSidebarSetter,
  useSidebarState,
  useUser,
} from '~/providers';
import { useCountdown } from '~/hooks';

import { addDays, format, getSearchParams } from '~/utils';

import { Text } from '../_legacy/Typography';
import { SegmentedControls } from './SegmentedControls';
import LanguageSelector from '../LanguageSelector';
import InstanceSelector from '../InstanceSelector';
import { UnlockProductButton } from '../UnlockProductButton';
import Button from '../Button';
import { RangeDatePicker } from '../DatePicker';

export type RouteMatch = ReturnType<typeof useMatches>[number];

export type HandleNavTab = {
  label: string;
  url: string;
};

export type HandleBreadcrumb = (match?: RouteMatch) => BreadCrumbProps[];

export type HandleFilters = {
  dateRangePicker?: boolean;
  kbSelector?: boolean;
  instanceSelector?: boolean;
};

export type Handle = {
  navTabs?: HandleNavTab[] | null;
  breadcrumb?: HandleBreadcrumb;
  filters?: HandleFilters;
  showInfoModalLink?: boolean;
};

type HandleExtended = Handle & { match: RouteMatch };

const defaultHandleFilters: HandleFilters = {
  dateRangePicker: false,
  kbSelector: false,
  instanceSelector: false,
};

const Header = () => {
  const sidebarState = useSidebarState();
  const setShowSidebar = useSidebarSetter();
  const { account } = useUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const matches = useMatches();

  const isTrial = account.features.trial_access?.amount === 1;

  const { segmentedControls, filters, breadCrumbs } = useMemo(() => {
    const handles: HandleExtended[] = matches.flatMap((match) =>
      match.handle ? [{ ...match.handle, match }] : [],
    );

    const segmentedControls = handles.reduce((acc, handle) => {
      if (handle.navTabs) {
        return [...acc, ...handle.navTabs];
      }
      if (handle.navTabs === null) {
        return [];
      }
      return acc;
    }, [] as HandleNavTab[]);

    const filters = handles.reduce(
      (acc, handle) => (handle.filters ? { ...acc, ...handle.filters } : acc),
      defaultHandleFilters,
    );

    const breadCrumbs = handles.flatMap((handle) =>
      handle.breadcrumb ? handle.breadcrumb(handle.match) : [],
    );

    return { segmentedControls, filters, breadCrumbs };
  }, [matches]);

  const handleChangeFilters = useCallback(
    ({
      timeRange,
      instanceUids,
    }: {
      timeRange?: any;
      instanceUids?: Array<Instance['uid']>;
    }) => {
      if (typeof instanceUids === 'undefined') {
        instanceUids = searchParams.getAll('instance_uid');
      }

      let { from, to } = getSearchParams(searchParams);

      if (timeRange) {
        from = format(timeRange.from, 'yyyy-MM-dd');
        to = format(timeRange.to, 'yyyy-MM-dd');
      }

      setSearchParams({
        instance_uid: instanceUids,
        ...(from && to && { from, to }),
      });
    },
    [searchParams, setSearchParams],
  );

  return (
    <div className="z-10 flex min-h-14 w-full items-center justify-between border-b border-neutral-250">
      <button
        type="button"
        className="flex h-full w-16 items-center justify-center border-r border-r-grey-light sm:hidden"
        onClick={() =>
          setShowSidebar({ ...sidebarState, show: !sidebarState.show })
        }
      >
        <Bars3Icon className="h-5 w-5 text-grey" />
      </button>

      <div className="ml-6 flex gap-4">
        {breadCrumbs.length > 0 ? (
          <BreadCrumbs breadcrumbs={breadCrumbs} />
        ) : null}
        <InfoModalLink />
      </div>

      {!isTrial && segmentedControls.length > 0 && (
        <HeaderGroup>
          <SegmentedControls segmentedControls={segmentedControls} />
        </HeaderGroup>
      )}

      {!isTrial &&
        (filters?.instanceSelector ||
          filters?.dateRangePicker ||
          filters?.kbSelector) && (
          <HeaderGroup className="justify-end pr-2">
            {filters?.dateRangePicker && (
              <div className="mr-4">
                <RangeDatePicker
                  handleSave={(timeRange) => handleChangeFilters({ timeRange })}
                />
              </div>
            )}
            {filters?.instanceSelector && (
              <InstanceSelector changeInstance={handleChangeFilters} />
            )}
            {filters?.kbSelector && <LanguageSelector />}
          </HeaderGroup>
        )}

      {isTrial && <TrialCountdown />}
    </div>
  );
};

type HeaderGroupProps = {
  className?: string;
  children: React.ReactNode;
};

const HeaderGroup = ({ children, className }: HeaderGroupProps) => (
  <div className={clsx('flex h-full flex-1 items-center', className)}>
    {children}
  </div>
);

type BreadCrumbsProps = {
  breadcrumbs: BreadCrumbProps[];
};

const BreadCrumbs = ({ breadcrumbs }: BreadCrumbsProps) => (
  <div className=" flex flex-1 items-center gap-2">
    {breadcrumbs.map(({ to, label, Icon }, i) => (
      <div className="contents" key={i}>
        <>
          {i !== 0 && (
            <ChevronRightIcon width="10px" height="10px" color="#99a5b9" />
          )}

          <BreadCrumb
            to={to}
            label={label}
            Icon={Icon}
            isLast={breadcrumbs.length === i + 1}
          />
        </>
      </div>
    ))}
  </div>
);

export type BreadCrumbProps = {
  label: string;
  to?: string;
  Icon?: HeroIcon;
  isLast?: boolean;
};
export const BreadCrumb = ({ to, label, Icon, isLast }: BreadCrumbProps) => {
  const { search } = useLocation();

  if (typeof to !== 'undefined') {
    return (
      <NavLink
        end
        to={`${to}${search}`}
        className={({ isActive }) =>
          clsx('flex items-center gap-2', {
            'text-primary-600': isActive,
            'text-neutral-400': !isActive,
          })
        }
      >
        {({ isActive }) => (
          <>
            {Icon && isActive && (
              <Icon className="h-5 w-5 shrink-0 text-inherit" />
            )}

            <Text
              className="!text-inherit"
              {...(isActive && { weight: 'semibold' })}
            >
              {label}
            </Text>
          </>
        )}
      </NavLink>
    );
  }

  return (
    <div
      className={clsx('flex items-center gap-2', {
        'text-primary-600': isLast,
        'text-neutral-400': !isLast,
      })}
    >
      {Icon && <Icon className="h-5 w-5 shrink-0 text-inherit" />}

      <Text className="!text-inherit" {...(isLast && { weight: 'semibold' })}>
        {label}
      </Text>
    </div>
  );
};

const TrialCountdown = () => {
  const { currentKnowledgeBase } = useKnowledgeBase();

  const { start: startDate } =
    currentKnowledgeBase?.dashboard_configuration?.trial ?? {};
  const { days, hours, minutes, isExpired } = useCountdown(
    startDate ? addDays(new Date(startDate), 7) : null,
  );

  return (
    <div className="flex w-fit items-center gap-3 px-6">
      {isExpired ? (
        <Text className="!text-neutral-600" weight="semibold">
          Your trial expired
        </Text>
      ) : (
        <>
          <Text className="!text-neutral-600" weight="semibold">
            Your trial will expire in:
          </Text>
          <Text className="!text-neutral-600">
            {startDate
              ? `${days} days ${hours} hours ${minutes} minutes`
              : 'Trial not started yet'}
          </Text>
        </>
      )}
      <UnlockProductButton />
    </div>
  );
};

export function InfoModalLink() {
  const matches = useMatches();
  const handles: HandleExtended[] = matches.flatMap((match) =>
    match.handle ? [{ ...match.handle, match }] : [],
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const filtered: HandleExtended[] = handles.filter(
    (handle) => handle?.showInfoModalLink === true,
  );

  if (filtered.length === 0) return null;

  const handle = filtered[filtered.length - 1];
  if (handle?.showInfoModalLink) {
    return (
      <Button
        variant="none"
        disabled={searchParams.get('infoModal') === 'true'}
        onClick={() => {
          searchParams.set('infoModal', 'true');
          setSearchParams(searchParams);
        }}
      >
        <QuestionMarkCircleIcon className="h-5 w-5 text-accent-purple-400" />
      </Button>
    );
  }
  return null;
}

export default Header;
