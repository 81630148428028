import { useEffect, useMemo, useState } from 'react';

import { useSearchParams } from '@remix-run/react';

import { format } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import { PopoverClose } from '@radix-ui/react-popover';

import type { DateRange } from 'react-day-picker';

import {
  calculatePreset,
  cn,
  datePresets,
  endOfDay,
  generateTimeSpanForSelector,
  getSearchParams,
  startOfDay,
  subDays,
} from '~/utils';

import { Button } from '../ui/button';
import { Calendar } from '../ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { Input } from '../Form';

export function RangeDatePicker({
  className,
  handleSave,
}: React.HTMLAttributes<HTMLDivElement> & {
  handleSave: (value: DateRange) => void;
}) {
  const [searchParams] = useSearchParams();

  const { from, to } = getSearchParams(searchParams);

  const value = useMemo(
    () =>
      generateTimeSpanForSelector({
        from,
        to,
      }),
    [from, to],
  );

  const [date, setDate] = useState<DateRange>({
    from: value.from,
    to: value.to,
  });

  const presetOptions = useMemo(
    () =>
      Object.entries(datePresets).map(([key, preset]) => ({
        label: preset.label,
        dates: preset.dates.map((days) => subDays(startOfDay(), days)),
        value: key,
      })),
    [],
  );

  const selectedPreset = useMemo(
    () => calculatePreset(date.from || new Date(), date.to || new Date()),
    [date],
  );

  useEffect(() => {
    if (value) {
      setDate({ from: value.from, to: value.to });
    }
  }, [value]);

  const handleSelect = (dateRange: DateRange | undefined) => {
    if (!dateRange) {
      return;
    }

    setDate(dateRange);
  };

  const handleSubmit = (open: boolean) => {
    if (open) return;

    handleSave({
      from: startOfDay(date.from),
      to: endOfDay(date.to || date.from),
    });
  };

  const handlePresetSelect = (preset: string) => {
    const [from, to] =
      presetOptions.find((option) => option.value === preset)?.dates || [];

    setDate({ from, to });
  };

  const handleManualDateInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    target: 'to' | 'from',
  ) => {
    const value = e.target.value;

    if (!/\d{1,2}\/\d{1,2}\/\d{2,4}/.test(value)) {
      return;
    }

    const targetDate = new Date(value.split('/').reverse().join('/'));

    if (isNaN(targetDate.getTime())) {
      return;
    }

    const newDate: DateRange = { ...date };
    newDate[target] = targetDate;

    if (newDate.from && newDate.to && newDate.to < newDate.from) {
      return;
    }

    setDate(newDate);
  };

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover onOpenChange={handleSubmit}>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={'outline'}
            className={cn(
              'min-w-[13.5rem] justify-start text-left text-sm font-medium text-accent-purple-400',
              !date && 'text-muted-foreground',
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, 'LLL dd, y')} -{' '}
                  {format(date.to, 'LLL dd, y')}
                </>
              ) : (
                format(date.from, 'LLL dd, y')
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="mr-1 flex h-96 w-auto p-0" align="center">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={handleSelect}
            numberOfMonths={2}
          />
          <div className="flex w-72 flex-col justify-between rounded-r-md border-l border-r-neutral-250 bg-neutral-100 p-4">
            <div className="flex gap-3">
              <div className="flex flex-col gap-1">
                <p className="text-sm font-medium leading-normal text-neutral-600">
                  From
                </p>
                <Input
                  placeholder="dd/mm/yyyy"
                  className="!py-1.5"
                  onChange={(e) => handleManualDateInput(e, 'from')}
                  value={date.from ? format(date.from, 'dd/MM/yyyy') : ''}
                />
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-sm font-medium leading-normal text-neutral-600">
                  To
                </p>
                <Input
                  placeholder="dd/mm/yyyy"
                  className="!py-1.5"
                  onChange={(e) => handleManualDateInput(e, 'to')}
                  value={date.to ? format(date.to, 'dd/MM/yyyy') : ''}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              {presetOptions.map((option) => (
                <Button
                  key={option.value}
                  variant={
                    selectedPreset === option.value ? 'selected' : 'secondary'
                  }
                  size="sm"
                  onClick={() => handlePresetSelect(option.value)}
                >
                  {option.label}
                </Button>
              ))}
            </div>
            <div className="flex w-full gap-2">
              <Button
                variant="secondary"
                onClick={() => handlePresetSelect('last7')}
                className="!w-full"
              >
                Clear
              </Button>
              <PopoverClose asChild>
                <Button variant="default" className="!w-full">
                  Apply
                </Button>
              </PopoverClose>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
