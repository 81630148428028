import {
  useCallback,
  useMemo,
  createContext,
  useContext,
  useState,
} from 'react';

import { Link, useNavigate } from '@remix-run/react';

import clsx from 'clsx';
import { useFormContext } from 'remix-validated-form';

import { XMarkIcon } from '@heroicons/react/24/outline';

import { Page, Button, StepCounter, DiffableForm, Text } from '~/components';

type MultiStepFormWrapperProps<T> = {
  returnTo: string;
  steps: number;
  current: number;
  formId: string;
  validator: any;
  defaultValues?: Partial<T>;
  submitBtnLabel: string;
  children: React.ReactNode;
  className?: string;
  formClassName?: string;
  confirmMessage?: string;
  customSubmit?: () => void;
  hideStepsInitial?: boolean;
  stepCounterClassName?: string;
  isModal?: boolean;
  title?: string;
  onChange?: (data: FormData) => void;
};

type ContextValue = {
  currentStep: number;
  setDisableNextStep: (value: boolean) => void;
  setHideSteps: (value: boolean) => void;
  setSteps: (value: number) => void;
  setSkippedSteps: (value: number[]) => void;
  setTitle: (value: string) => void;
};

const MultiStepFormWrapperContext = createContext<
  ContextValue | Record<string, never>
>({});

export function MultiStepFormWrapperProvider<T>({
  returnTo,
  steps: stepsCount,
  current,
  hideStepsInitial = false,
  isModal = false,
  title,
  ...rest
}: MultiStepFormWrapperProps<T>) {
  const [currentStep, setCurrentStep] = useState(current);
  const [disableNextStep, setDisableNextStep] = useState(false);
  const [hideSteps, setHideSteps] = useState(hideStepsInitial);
  const [steps, setSteps] = useState(stepsCount);
  const [skippedSteps, setSkippedSteps] = useState<number[]>([]);
  const [stepTitle, setTitle] = useState(title);

  const navigate = useNavigate();

  const formContex = useFormContext(rest.formId);

  const handleNext = useCallback(() => {
    if (currentStep === steps) {
      formContex.submit();

      return;
    }

    setCurrentStep((prev) =>
      skippedSteps.includes(prev + 1) ? prev + 2 : prev + 1,
    );
  }, [currentStep, steps, formContex, skippedSteps]);

  const handlePrevious = useCallback(() => {
    if (currentStep === 1) {
      return navigate(returnTo);
    }

    setCurrentStep((prev) =>
      skippedSteps.includes(prev - 1) ? prev - 2 : prev - 1,
    );
  }, [currentStep, navigate, returnTo, skippedSteps]);

  const value = useMemo<ContextValue>(
    () => ({
      currentStep,
      setDisableNextStep,
      setHideSteps,
      setSteps,
      setSkippedSteps,
      setTitle,
    }),
    [currentStep, setDisableNextStep],
  );

  return (
    <MultiStepFormWrapperContext.Provider value={value}>
      {isModal ? (
        <ModalForm
          returnTo={returnTo}
          steps={steps}
          hideStepsInitial={hideStepsInitial}
          disableNextStep={disableNextStep}
          hideSteps={hideSteps}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          title={stepTitle}
          {...rest}
        />
      ) : (
        <PageForm
          returnTo={returnTo}
          steps={steps}
          hideStepsInitial={hideStepsInitial}
          disableNextStep={disableNextStep}
          hideSteps={hideSteps}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          title={stepTitle}
          {...rest}
        />
      )}
    </MultiStepFormWrapperContext.Provider>
  );
}

export const useMultiStepFormWrapperState = () => {
  const context = useContext(MultiStepFormWrapperContext);

  if (context === undefined) {
    throw new Error(
      'useMultiStepFormWrapperState must be used within a MultiStepFormWrapperProvider',
    );
  }

  return context;
};

type FormComponentProps<T> = Omit<MultiStepFormWrapperProps<T>, 'current'> & {
  hideSteps: boolean;
  handlePrevious: () => void;
  handleNext: () => void;
  disableNextStep: boolean;
};

function ModalForm<T>({
  title,
  steps,
  hideSteps,
  stepCounterClassName,
  returnTo,
  children,
  handlePrevious,
  customSubmit,
  handleNext,
  disableNextStep,
  submitBtnLabel,
  validator,
  formId,
  defaultValues,
  confirmMessage,
  formClassName,
  onChange,
}: FormComponentProps<T>) {
  const { currentStep } = useMultiStepFormWrapperState();

  return (
    <div className="flex h-full flex-col">
      <div className="flex w-full items-center justify-between border-b border-b-neutral-250 bg-neutral-0 px-6 py-4">
        <Text weight="semibold" size="normal" className="!text-neutral-600">
          {title}
        </Text>

        {steps > 1 && !hideSteps && (
          <StepCounter
            steps={steps}
            current={currentStep}
            className={clsx('max-w-[20%]', stepCounterClassName)}
          />
        )}

        <Link to={returnTo}>
          <Button
            variant="icon"
            Icon={XMarkIcon}
            className="bg-neutral-0 !p-2"
            iconClassName="!h-3 !w-3"
          />
        </Link>
      </div>

      <DiffableForm
        id={formId}
        method="post"
        validator={validator}
        defaultValues={defaultValues}
        externalSubmitBtn={true}
        onChange={onChange}
        onSubmit={(data, e) => {
          if (confirmMessage && !window.confirm(confirmMessage)) {
            e.preventDefault();
          }

          if (customSubmit) {
            e.preventDefault();
          }
        }}
        className={clsx(formClassName, 'flex h-full flex-col')}
        action="/backstage/plan/upgrade"
      >
        <div className="flex h-full flex-1 overflow-auto bg-neutral-0">
          {children}
        </div>

        <div className="flex h-fit justify-end gap-2 border-t border-t-neutral-250 bg-neutral-50 px-6 py-4">
          <Button className="w-[100px]" onClick={handlePrevious}>
            {currentStep === 1 ? 'Cancel' : 'Back'}
          </Button>

          <Button
            className="w-[100px]"
            variant="primary"
            onClick={
              customSubmit && currentStep === steps ? customSubmit : handleNext
            }
            disabled={disableNextStep}
          >
            {steps === currentStep ? submitBtnLabel : 'Next'}
          </Button>
        </div>
      </DiffableForm>
    </div>
  );
}

function PageForm<T>({
  steps,
  hideSteps,
  stepCounterClassName,
  returnTo,
  children,
  handlePrevious,
  customSubmit,
  handleNext,
  disableNextStep,
  submitBtnLabel,
  className,
  formId,
  validator,
  defaultValues,
  confirmMessage,
  formClassName,
  onChange,
}: FormComponentProps<T>) {
  const { currentStep } = useMultiStepFormWrapperState();

  return (
    <Page
      className={clsx(
        'relative flex flex-col items-center overflow-auto !p-0',
        className,
      )}
    >
      <Link to={returnTo}>
        <Button
          className="absolute top-16 right-4 z-10"
          variant="icon"
          Icon={XMarkIcon}
        />
      </Link>

      {steps > 1 && !hideSteps && (
        <StepCounter
          steps={steps}
          current={currentStep}
          className={clsx('mt-16', stepCounterClassName)}
        />
      )}

      <DiffableForm
        id={formId}
        method="post"
        validator={validator}
        defaultValues={defaultValues}
        externalSubmitBtn={true}
        onChange={onChange}
        onSubmit={(_, e) => {
          if (confirmMessage && !window.confirm(confirmMessage)) {
            e.preventDefault();
          }

          if (customSubmit) {
            e.preventDefault();
          }
        }}
        className={clsx('w-1/2', formClassName)}
      >
        {children}

        <div
          className={clsx(
            'absolute bottom-0 left-0 mt-auto flex w-full justify-center gap-4 border border-neutral-200 bg-white p-4 transition-[inset] delay-150 duration-300',
          )}
          style={{ boxShadow: '0px 5px 20px rgba(18, 21, 31, 0.15)' }}
        >
          <Button className="w-56" onClick={handlePrevious}>
            {currentStep === 1 ? 'Cancel' : 'Back'}
          </Button>

          <Button
            type={currentStep === steps ? 'submit' : 'button'}
            className="w-56"
            variant="primary"
            onClick={
              customSubmit && currentStep === steps ? customSubmit : handleNext
            }
            disabled={disableNextStep}
          >
            {steps === currentStep ? submitBtnLabel : 'Next'}
          </Button>
        </div>
      </DiffableForm>
    </Page>
  );
}
