import React from 'react';

import { useLocation } from '@remix-run/react';

import clsx from 'clsx';

type PageProps = {
  className?: string;
  StickyHeader?: React.ReactNode;
} & JSX.IntrinsicElements['div'];

const Page = ({ children, className, StickyHeader, ...rest }: PageProps) => {
  const { pathname } = useLocation();
  const isClusterView =
    (pathname.includes('trending') &&
      !pathname.includes('trending_questions')) ||
    pathname.includes('knowledge_gap');

  return (
    <div
      className={clsx(
        'h-[calc(100vh-theme(space.16))] w-full p-4 sm:h-[calc(100vh-theme(space.12))] md:p-6',
        {
          'relative !pt-0': StickyHeader,
          '!p-0': isClusterView,
        },
        className,
      )}
      {...rest}
    >
      {StickyHeader && (
        <div className="sticky top-0 -mx-4 bg-neutral-100 px-4 pt-4 md:-mx-6 md:px-6 md:pt-6">
          {StickyHeader}
        </div>
      )}

      {children}
    </div>
  );
};

export default Page;
