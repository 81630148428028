import React, { useMemo, useState } from 'react';

import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getFilteredRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { rankings, rankItem } from '@tanstack/match-sorter-utils';
import clsx from 'clsx';
import { motion } from 'framer-motion';

import type { RankingInfo } from '@tanstack/match-sorter-utils';
import type {
  ColumnDef,
  Row,
  FilterFn,
  SortingState,
} from '@tanstack/react-table';

import { fadeIn } from '~/utils';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table';
import LoadingSpinner from '../LoadingSpinner';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value, {
    threshold: rankings.CONTAINS,
  });

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export type TableCompactProps<T extends object> = {
  columns: Array<ColumnDef<T>>;
  data: T[];
  className?: string;
  headerClassName?: string;
  headerCellClassName?: string;
  bodyClassName?: string;
  bodyRowClassName?: string;
  getBodyRowClassName?: (row: T) => string | undefined;
  bodyCellClassName?: string;
  globalFilter?: string;
  hiddenColumns?: Record<string, boolean>;
  ExpandedComponent?: ({ row }: { row: T }) => JSX.Element;
  defaultSorting?: SortingState;
  onRowClick?: (row: T) => void;

  loadNextPage?: () => void;
  canLoadMore?: boolean;
  isLoading?: boolean;

  selectedRowId?: string;
};

const TableCompact = <T extends object>({
  columns,
  data,
  globalFilter,
  hiddenColumns = {},
  defaultSorting = [],
  onRowClick,

  loadNextPage,
  canLoadMore,
  isLoading,
  selectedRowId,
}: TableCompactProps<T>) => {
  const tableColumns = useMemo<Array<ColumnDef<T>>>(() => columns, [columns]);
  const tableData = useMemo(() => data, [data]);

  const [sorting, setSorting] = useState<SortingState>(defaultSorting);

  const table = useReactTable({
    columns: tableColumns,
    data: tableData,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      globalFilter,
      columnVisibility: hiddenColumns,
      sorting,
    },
    onSortingChange: setSorting,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) => row.id?.toString(),
  });

  const tableContainerRef = React.useRef<HTMLTableElement>(null);

  const { rows } = table.getRowModel();
  const virtualRows = useVirtualizer({
    getScrollElement: () => tableContainerRef.current,
    count: rows.length,
    estimateSize: () => 38,
  });

  return (
    <motion.div
      className="w-full overflow-auto"
      onScroll={(e) => {
        if (
          !isLoading &&
          canLoadMore &&
          Math.abs(
            e.currentTarget.scrollHeight -
              e.currentTarget.clientHeight -
              e.currentTarget.scrollTop,
          ) < 1
        ) {
          loadNextPage && loadNextPage();
        }
      }}
      {...fadeIn}
    >
      <Table ref={tableContainerRef}>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow
              key={headerGroup.id}
              className="!border-solid bg-neutral-100"
            >
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id} className="pl-4 first:!pl-6">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>

        <TableBody>
          {virtualRows.getVirtualItems().length ? (
            <>
              {virtualRows.getVirtualItems().map((virtualRow) => {
                const row = rows[virtualRow.index] as Row<T>;

                return (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && 'selected'}
                    className={clsx({
                      'cursor-pointer': !!onRowClick,
                      '!bg-neutral-150': selectedRowId === row.id,
                    })}
                    onClick={() => onRowClick && onRowClick(row.original)}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id} className="first:pl-6">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}

              {isLoading && (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    <LoadingSpinner />
                  </TableCell>
                </TableRow>
              )}
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </motion.div>
  );
};

export default TableCompact;
