import { useMemo } from 'react';

import {
  ArrowSmallLeftIcon,
  ArrowSmallRightIcon,
} from '@heroicons/react/20/solid';

import Button from './Button';
import { Text } from './_legacy/Typography';

type PaginationProps = {
  page: number;
  pages: number;
  entries: number;
  onPageChange: (page: number) => void;
  disabledPrev?: boolean;
  disabledNext?: boolean;
  loading?: boolean;
  limit?: number;
};
const Pagination = ({
  disabledPrev = false,
  disabledNext = false,
  loading = false,
  limit = 5,
  page,
  pages,
  entries,
  onPageChange,
}: PaginationProps) => {
  const numbers = useMemo(() => {
    let start = 0;

    if (page > 2 && pages > limit) {
      start = page - 2;
    }

    if (pages > limit && page >= pages - 2) {
      start = pages - limit;
    }

    return Array.from({ length: pages }, (_, i) => i + 1).splice(start, limit);
  }, [page, pages, limit]);

  return (
    <div className="flex items-center gap-4">
      <Button
        variant="icon"
        Icon={ArrowSmallLeftIcon}
        onClick={() => onPageChange(page - 1)}
        disabled={disabledPrev || loading}
      />

      {numbers.map((number) => (
        <Button
          key={number}
          variant="icon"
          onClick={() => onPageChange(number)}
          disabled={number === page || loading}
          className="!px-2 !py-1"
        >
          <Text>{number}</Text>
        </Button>
      ))}

      <Button
        variant="icon"
        Icon={ArrowSmallRightIcon}
        onClick={() => onPageChange(page + 1)}
        disabled={disabledNext || loading}
      />

      <Text>
        {`page: ${page} /
            ${pages} (${entries} ${entries === 1 ? 'entry' : 'entries'})`}
      </Text>
    </div>
  );
};

export default Pagination;
