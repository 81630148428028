import { forwardRef } from 'react';

import { ChevronRightIcon, FolderIcon } from '@heroicons/react/24/outline';

import { useSidebarState } from '~/providers/sidebar';
import { useUser } from '~/providers/user';

import { SidebarCardButton } from '../Layout/SideBar/SidebarCardButton';
import { Text } from '../_legacy/Typography';

export const AccountButton = forwardRef<HTMLButtonElement>((props, ref) => {
  const { account } = useUser();
  const { minimise } = useSidebarState();

  return (
    <SidebarCardButton ref={ref} {...props}>
      <FolderIcon className="h-5 w-5 text-white-off" />

      {!minimise && (
        <>
          <div className="flex w-20 flex-col text-left">
            <Text className="!text-white-off">Account</Text>

            <Text size="sm" className="truncate !text-neutral-350">
              {account.title}
            </Text>
          </div>

          <ChevronRightIcon className="h-4 text-neutral-0" />
        </>
      )}
    </SidebarCardButton>
  );
});

AccountButton.displayName = 'AccountButton';
