import { parseInitials } from '~/utils';

import { Text } from '~/components';

import UserInitialsCircle from './UserInitialsCircle';

export type UserInfoProps = {
  fullName: string;
  email: string;
};
const UserInfo = ({ fullName, email }: UserInfoProps) => (
    <div className="flex items-center gap-3">
      <UserInitialsCircle initials={parseInitials(fullName)} />

      <div className="flex flex-col gap-1 text-left">
        <Text variant="heading">{fullName}</Text>
        <Text>{email}</Text>
      </div>
    </div>
  );

export default UserInfo;
