import clsx from 'clsx';

import type { HeroIcon } from '~/types';

import { Text } from '~/components';

type PillProps = {
  Icon?: HeroIcon;
  iconClassName?: string;
  textLabel?: string;
};
export const Pill = ({ Icon, iconClassName, textLabel }: PillProps) => (
  <div className="flex w-fit flex-row items-center gap-2 rounded-full border border-[#D6DCE7] px-2 py-1">
    {Icon && (
      <Icon className={clsx('h-4 w-4 !text-neutral-450', iconClassName, {})} />
    )}

    <Text size={'sm'} className="!leading-[150%] !text-neutral-450">
      {textLabel}
    </Text>
  </div>
);
