import { useRef } from 'react';

import { Link, useLoaderData, useLocation, useSubmit } from '@remix-run/react';

import clsx from 'clsx';
import { confirmAlert } from 'react-confirm-alert';

import { TrashIcon, Cog6ToothIcon } from '@heroicons/react/24/solid';
import { SparklesIcon } from '@heroicons/react/24/outline';

import type { PopupActions } from 'reactjs-popup/dist/types';

import type { Source } from '~/models';

import { useUser } from '~/providers';

import { isRaffleAdmin } from '~/utils';

import { Text } from './_legacy/Typography';
import Button from './Button';
import DynamicTooltip from './DynamicTooltip';
import {
  LegacyConfirmModal,
  CONFIRM_MODAL_OVERLAY_CLASSNAME,
} from './_legacy/LegacyConfirmModal';

type SourceOptionsProps = {
  source: {
    id: Source['id'];
    configuration?: Source['configuration'];
    type: Source['type'];
  };
  answersView?: boolean;
  archived?: boolean;
};
const SourceOptions = ({
  source: { id, type, configuration },
  answersView = false,
  archived = false,
}: SourceOptionsProps) => {
  const {
    account: { features, industry },
    user: { roles },
  } = useUser();
  const { isLimitReached } = useLoaderData<{ isLimitReached: boolean }>();

  const submit = useSubmit();
  const { pathname, search } = useLocation();

  const ref = useRef<PopupActions>();

  const isAdmin = isRaffleAdmin(roles);

  const isTrial = industry === 'trial';

  const performAction = (action: 'archive' | 'restore' | 'delete' | 'sync') => {
    ref.current?.close();
    confirmAlert({
      overlayClassName: CONFIRM_MODAL_OVERLAY_CLASSNAME,
      customUI: ({ onClose }) => (
        <LegacyConfirmModal
          title={`${action[0].toUpperCase() + action.slice(1)} source`}
          body={[`Are you sure you want to ${action} this index?`]}
          onClose={onClose}
          onAccept={() =>
            submit(
              {
                id: id.toString(),
                _action: action,
              },
              {
                action: `${pathname}${search}`,
                method: 'post',
              },
            )
          }
        />
      ),
    });
  };

  return (
    <div
      className={clsx('flex items-center justify-end gap-3', {
        'flex-col !gap-2': answersView,
      })}
    >
      {!answersView && !archived && (
        <Link to={`/sources/${id}/answers`}>
          <Button>View content</Button>
        </Link>
      )}

      {archived ? (
        <>
          <Button Icon={TrashIcon} onClick={() => performAction('delete')} />

          <DynamicTooltip
            offset={{ x: -300, y: -60 }}
            content={
              isLimitReached ? (
                <div className="flex flex-col items-center gap-1">
                  <Text size="sm" className="text-neutral-100">
                    You’ve reached your indexes limit (
                    {features.sources?.amount})
                  </Text>

                  <Text size="sm" className="text-neutral-100">
                    Delete an index or upgrade your plan to create a new one
                  </Text>
                </div>
              ) : null
            }
          >
            <Button
              Icon={SparklesIcon}
              className={clsx({
                'opacity-50': isLimitReached,
                'pointer-events-none': isLimitReached && !isAdmin,
              })}
              onClick={() => performAction('restore')}
            />
          </DynamicTooltip>
        </>
      ) : (
        <>
          <Link
            to={`/sources/${id}/settings`}
            className={clsx({
              'w-full !justify-start': answersView,
              'pointer-events-none cursor-not-allowed': isTrial,
            })}
          >
            <Button
              Icon={Cog6ToothIcon}
              className={clsx({ 'w-full !justify-start': answersView })}
              disabled={isTrial}
            >
              {answersView ? 'Settings' : ''}
            </Button>
          </Link>

          <Button
            Icon={TrashIcon}
            onClick={() => performAction('archive')}
            className={clsx({ 'w-full !justify-start': answersView })}
            disabled={isTrial}
          >
            {answersView ? 'Archive' : ''}
          </Button>
        </>
      )}
    </div>
  );
};

export default SourceOptions;
