import clsx from 'clsx';

import { CheckIcon } from '@heroicons/react/20/solid';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

import type { HeroIcon } from '~/types';

import Button from '../Button';
import { Text } from './Typography';

type ConfirmModalProps = {
  title: string;
  className?: string;
  body?: string[];
  children?: React.ReactNode;
  Icon?: HeroIcon;
  iconClassName?: string;
  acceptBtnText?: string;
  rejectBtnText?: string;
  onClose: () => void;
  onAccept?: () => void;
  onReject?: () => void;
  noCancelBtn?: boolean;
  acceptBtnDisabled?: boolean;
};

/**
 * @deprecated This component is considered legacy and should not be used in new development.
 */

export const LegacyConfirmModal = ({
  title,
  className,
  body = [],
  children = null,
  Icon,
  iconClassName,
  acceptBtnText = 'Confirm',
  rejectBtnText = 'Cancel',
  onClose,
  onAccept,
  onReject,
  noCancelBtn = false,
  acceptBtnDisabled = false,
}: ConfirmModalProps) => (
  <div
    className={clsx(
      'flex min-w-[500px] max-w-[600px] flex-col overflow-hidden rounded-sm border border-neutral-200 bg-neutral-0 shadow-drop',
      className,
    )}
  >
    <div className="flex flex-col gap-4 p-6">
      <div className="flex items-center gap-2">
        {Icon && <Icon className={clsx('h-6 w-6', iconClassName)} />}

        <Text variant="heading" size="md" weight="semibold">
          {title}
        </Text>
      </div>

      {body.map((text) => (
        <Text
          key={text}
          size="normal"
          className="!leading-normal !text-neutral-550"
        >
          {text}
        </Text>
      ))}

      {children}
    </div>

    <div className="flex w-full items-center gap-6 border-t border-t-neutral-200 bg-neutral-50 p-6">
      <Button
        className="w-full"
        variant="primary"
        Icon={CheckIcon}
        onClick={() => {
          onAccept?.();
          onClose();
        }}
        disabled={acceptBtnDisabled}
      >
        {acceptBtnText}
      </Button>

      {!noCancelBtn && (
        <Button
          className="w-full"
          onClick={() => {
            onReject?.();
            onClose();
          }}
        >
          {rejectBtnText}
        </Button>
      )}
    </div>
  </div>
);

type DeleteConfirmModalProps = {
  resource: string;
} & Omit<ConfirmModalProps, 'title' | 'body'>;

export const DeleteConfirmModal = ({
  resource,
  ...props
}: DeleteConfirmModalProps) => (
  <LegacyConfirmModal
    {...props}
    title={`Are you sure you want to delete ${resource}?`}
    body={[
      `This action cannot be undone, and the ${resource} will be permanently removed.`,
      'Please confirm your decision to proceed.',
    ]}
    Icon={ExclamationCircleIcon}
    iconClassName="text-secondary-400"
  />
);

export const CONFIRM_MODAL_OVERLAY_CLASSNAME =
  '!bg-primary-600 !bg-opacity-50 !z-[1000]';
