import { useEffect, useMemo, useState } from 'react';

import { useFetcher, useOutletContext } from '@remix-run/react';

import { useDropzone } from 'react-dropzone-esm';
import clsx from 'clsx';

import {
  ArrowUpTrayIcon,
  CheckIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';
import {
  NoSymbolIcon,
  CloudArrowUpIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/outline';

import Button from './Button';
import { H3, Text } from './_legacy/Typography';
export type ActionData = {
  isSuccess: boolean;
};

enum UPLOAD_STATUS {
  INITIAL = 'INITIAL',
  UPLOADING = 'UPLOADING',
  COMPLETED = 'COMPLETED',
}

export enum FILE_STATUS {
  INITIAL = 'INITIAL',
  UPLOADING = 'UPLOADING',
  QUEUED = 'QUEUED',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export const ALLOWED_FILE_EXTENSIONS = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx',
  ],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
};

type FileUploadContextData = {
  files: FileUpload[];
};

export type FileUpload = { status: FILE_STATUS; file: File };

export const ManualUploadDropzone = () => {
  const [files, setFiles] = useState<FileUpload[]>([]);
  const [uploadStatus, setUploadStatus] = useState(UPLOAD_STATUS.INITIAL);

  const { files: parentFiles } = useOutletContext<FileUploadContextData>();

  useEffect(() => {
    setFiles((prev) => [...prev, ...parentFiles]);
  }, [parentFiles]);

  const handleDrop = (acceptedFiles: File[]) => {
    if (uploadStatus === UPLOAD_STATUS.INITIAL) {
      setFiles((prev) => [
        ...prev,
        ...acceptedFiles.map((file) => ({ file, status: FILE_STATUS.INITIAL })),
      ]);
    }
  };

  const handleRemove = (name: string) =>
    setFiles(files.filter(({ file }) => file.name !== name));

  const handleUpload = () => {
    setUploadStatus(UPLOAD_STATUS.UPLOADING);

    setFiles((prev) => {
      const updated = prev.map((file) => ({
        ...file,
        status: FILE_STATUS.QUEUED,
      }));
      updated[0].status = FILE_STATUS.UPLOADING;
      return updated;
    });
  };

  const handleCompleteUpload = (name: string, isSuccess = true) => {
    const completedIndex = files.findIndex(({ file }) => file.name === name);
    const updated = [...files];

    updated[completedIndex].status = isSuccess
      ? FILE_STATUS.SUCCESS
      : FILE_STATUS.ERROR;

    if (updated[completedIndex + 1]) {
      updated[completedIndex + 1].status = FILE_STATUS.UPLOADING;
      setFiles(updated);
    } else {
      setFiles(updated);
      setUploadStatus(UPLOAD_STATUS.COMPLETED);
    }
  };

  const buttonText = useMemo(() => {
    switch (uploadStatus) {
      case UPLOAD_STATUS.UPLOADING:
        return 'Uploading...';
      case UPLOAD_STATUS.COMPLETED:
        return 'Upload completed';
      default:
        return 'Upload';
    }
  }, [uploadStatus]);

  const {
    getRootProps,
    getInputProps,
    fileRejections,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: handleDrop,
    accept: ALLOWED_FILE_EXTENSIONS,
  });

  return (
    <div className="flex flex-col gap-4 bg-neutral-0">
      <div
        {...getRootProps({
          className: clsx(
            'flex cursor-pointer flex-col justify-center rounded-sm border border-dashed border-neutral-300 bg-neutral-50 px-8 py-16 text-center',
            {
              'border-green-dark': isDragAccept,
              'border-red-dark': isDragReject,
              'border-grey': !isDragActive,
            },
          ),
        })}
      >
        <input {...getInputProps()} />
        <div className="flex h-8 flex-col justify-center gap-2">
          {isDragActive ? (
            <ActiveDropzoneText
              isDragAccept={isDragAccept}
              isDragReject={isDragReject}
            />
          ) : (
            <DropzoneText />
          )}
        </div>
      </div>

      {files.length > 0 && (
        <div className="flex flex-col gap-2">
          <H3>Accepted Files</H3>

          {files.map(({ file, status }) => (
            <FileStatus
              key={file.name}
              file={file}
              status={status}
              onRemove={handleRemove}
              onComplete={handleCompleteUpload}
            />
          ))}
        </div>
      )}

      {fileRejections.length > 0 && (
        <div className="flex flex-col gap-2">
          <H3>Rejected Files</H3>

          {fileRejections.map(({ file }) => (
            <FileStatus
              key={file.name}
              file={file}
              status={FILE_STATUS.ERROR}
            />
          ))}
        </div>
      )}

      <Button
        onClick={handleUpload}
        disabled={uploadStatus !== UPLOAD_STATUS.INITIAL || files.length === 0}
        Icon={ArrowUpTrayIcon}
        className="shadow-none"
      >
        {buttonText}
      </Button>
    </div>
  );
};

type FileStatusProps = {
  file: File;
  status: FILE_STATUS;
  onRemove?: (name: string) => void;
  onComplete?: (name: string, isSuccess: boolean) => void;
};

const FileStatus = ({
  file,
  status,
  onRemove,
  onComplete,
}: FileStatusProps) => {
  const fetcher = useFetcher<ActionData>();

  const icon = useMemo(() => {
    switch (status) {
      case FILE_STATUS.SUCCESS:
        return CheckIcon;
      case FILE_STATUS.UPLOADING:
        return CloudArrowUpIcon;
      case FILE_STATUS.QUEUED:
        return EllipsisVerticalIcon;
      case FILE_STATUS.ERROR:
        return NoSymbolIcon;
      default:
        return TrashIcon;
    }
  }, [status]);

  useEffect(() => {
    if (status === FILE_STATUS.UPLOADING) {
      const formData = new FormData();
      formData.append('file', file);
      fetcher.submit(formData, {
        method: 'post',
        encType: 'multipart/form-data',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (fetcher.data && status !== 'SUCCESS') {
      const { isSuccess } = fetcher.data;
      onComplete?.(file.name, isSuccess);
    }
  }, [fetcher.data, file.name, onComplete, status]);

  return (
    <div className="flex items-center gap-3">
      <Button
        className={clsx('flex items-center justify-center rounded-full !p-1', {
          '!text-secondary-300': status === FILE_STATUS.ERROR,
          '!text-accent-green-400': status === FILE_STATUS.SUCCESS,
          '!text-blue': status === FILE_STATUS.UPLOADING,
          '!text-orange': status === FILE_STATUS.QUEUED,
          '!text-secondary-200': status === FILE_STATUS.INITIAL,
        })}
        iconClassName="!h-4 !w-4 !text-inherit"
        onClick={() => onRemove && onRemove(file.name)}
        disabled={status !== FILE_STATUS.INITIAL}
        Icon={icon}
      />

      <Text>
        {file.name} - {file.size} bytes
      </Text>
    </div>
  );
};

export const DropzoneText = () => (
  <>
    <div className="flex justify-center">
      <ArrowUpTrayIcon className="h-5 w-5 text-neutral-450" />
    </div>
    <Text
      weight="medium"
      size="normal"
      className="flex items-center gap-1"
      as="div"
    >
      Drag & Drop or <p className="text-accent-purple-400">Choose file</p> to
      upload
    </Text>
    <Text size="sm" variant="faded">
      {Object.values(ALLOWED_FILE_EXTENSIONS).join(', ')}
    </Text>
    <Text>(15mb max)</Text>
  </>
);

type ActiveDropzoneTextProps = {
  isDragAccept: boolean;
  isDragReject: boolean;
};

export const ActiveDropzoneText = ({
  isDragAccept,
  isDragReject,
}: ActiveDropzoneTextProps) => (
  <Text
    className={clsx({
      'text-accent-green-400': isDragAccept,
      'text-secondary-300': isDragReject,
    })}
    weight="medium"
    size="normal"
    as="div"
  >
    {isDragAccept ? 'Drop files here' : 'File type not allowed'}
  </Text>
);
