import clsx from 'clsx';

import {
  RectangleGroupIcon,
  CircleStackIcon,
  StarIcon,
} from '@heroicons/react/24/outline';

import type { ToolType } from '~/models';

import { useUser } from '~/providers';

import { isRaffleAdmin, toolTypeDict } from '~/utils';

import Chip from '../Chip';
import { FormStepTitle } from '../FormStepTitle';
import { RequestAccessButton } from '../RequestAccessButton';
import { SelectCardOutlined } from '../SelectCardOutlined';
import { FieldError } from './ToolFieldError';
import { Text } from '../_legacy/Typography';

type ToolTypeSectionProps = {
  error: string;
  handleToolTypeChange: (selectedToolType: ToolType) => void;
  toolType: ToolType;
  isWidgetsLimitReached: boolean;
};

export const ToolTypeSection = ({
  error,
  handleToolTypeChange,
  toolType,
  isWidgetsLimitReached,
}: ToolTypeSectionProps) => {
  const {
    account: { features },
    user: { roles },
  } = useUser();
  const isAdmin = isRaffleAdmin(roles);

  const toolTypes = [
    { ...toolTypeDict['widget'], Icon: RectangleGroupIcon },
    { ...toolTypeDict['api'], Icon: CircleStackIcon },
  ];

  return (
    <>
      <FormStepTitle
        title="Select a type of Search UI"
        description="Your choice should be dependent on the intended use"
      />

      <FieldError message={error} />

      <div className="flex w-full flex-col gap-4">
        {toolTypes.map(({ description, id, title, Icon }) => {
          const isFeatureNotPurchased =
            (id === 'widget' && isWidgetsLimitReached) ||
            (id === 'api' && !features.public_api?.amount);

          return (
            <SelectCardOutlined
              key={id}
              id={id}
              onClick={handleToolTypeChange}
              selected={toolType === id}
              className="!h-fit min-h-[85px] items-center gap-3 !rounded-md border p-3"
              disabled={isFeatureNotPurchased}
            >
              <>
                <div className="flex aspect-square h-[54px] items-center justify-center rounded-sm border border-neutral-250 bg-neutral-50">
                  <Icon className="h-5 w-5" />
                </div>

                <div className="flex w-full flex-col justify-start">
                  <div className="flex items-center justify-between gap-2">
                    <Text
                      className={clsx({
                        '!text-neutral-400': isFeatureNotPurchased,
                        '!text-neutral-600': !isFeatureNotPurchased,
                      })}
                      weight="medium"
                      size="normal"
                    >
                      {title}
                    </Text>

                    {id === 'widget' && !isFeatureNotPurchased && (
                      <Chip
                        Icon={StarIcon}
                        className="!py-1"
                        iconClassName="!text-accent-purple-400"
                        textClassName="!text-accent-purple-400 font-medium text-xs leading-normal"
                      >
                        Most popular
                      </Chip>
                    )}

                    {isFeatureNotPurchased && !isAdmin && (
                      <RequestAccessButton feature="widgets" />
                    )}
                  </div>

                  <Text
                    className={clsx('leading-[150%]', {
                      '!text-neutral-350': isFeatureNotPurchased,
                      '!text-neutral-450': !isFeatureNotPurchased,
                    })}
                    size="base"
                  >
                    {description}
                  </Text>
                </div>
              </>
            </SelectCardOutlined>
          );
        })}
      </div>
    </>
  );
};
