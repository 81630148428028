import React from 'react';

import { Link, useFetcher } from '@remix-run/react';

import clsx from 'clsx';
import { ValidatedForm } from 'remix-validated-form';
import { withZod } from '@remix-validated-form/with-zod';
import { z } from 'zod';

import {
  CloudArrowDownIcon,
  ExclamationTriangleIcon,
  ShieldExclamationIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { RocketLaunchIcon } from '@heroicons/react/24/outline';

import { useCountdown } from '~/hooks';

import { addDays } from '~/utils';

import { Text } from './_legacy/Typography';
import Button from './Button';
import { HiddenInput } from './ValidatedForm';
import { UnlockProductButton } from './UnlockProductButton';

const unlockProductValidator = withZod(z.object({ subaction: z.string() }));

const statusSteps = [
  'Set up the sources',
  'Scrape your website content',
  'Training your AI model',
  'Validating the performances',
  'Deploying your AI model',
];

const statusTitle = {
  ongoing: {
    title: 'We are processing your data source',
    subtitle: 'Estimated time: around 15 min',
    description:
      'We will notify you as soon as the widget is ready to use. Meanwhile, you can explore the Raffle Insights functionalities.',
  },
  success: {
    title: 'Your AI model is ready to use!',
    subtitle: 'Your trial will expire in: ',
    description:
      'The widget might require some background work to be fully optimized.',
  },
  failed: {
    title:
      'We found some issues in your scrape. Please try with a different URL',
    subtitle: '',
    description:
      'Raffle ran into an unexpected error during your scrape. This could be due to various reasons, such as website firewalls or server errors. We are working on fixing it and will reach out as soon as it’s done. ',
  },
};

export type TrialStatusProps =
  | {
      syncStatus: 'failed' | 'ongoing';
      toolId?: number;
      startDate?: string;
      unlockRequested: boolean;
    }
  | {
      syncStatus: 'success';
      toolId: number;
      startDate?: string;
      unlockRequested: boolean;
    };

export const TrialStatusHome = ({
  syncStatus,
  toolId,
  startDate,
  unlockRequested,
}: TrialStatusProps) => {
  const { days, hours, minutes, isExpired } = useCountdown(
    startDate ? addDays(new Date(startDate), 7) : null,
  );

  if (isExpired) {
    return (
      <div className="flex w-full gap-6">
        {/* <a href="mailto:hello@raffle.ai" target="_blank" rel="noreferrer">
          <Button variant="secondary" className="h-fit">
            Contact Support
          </Button>
        </a> */}
        <ValidatedForm
          validator={unlockProductValidator}
          defaultValues={{ subaction: '' }}
          action="/action/trial"
          method="post"
        >
          <HiddenInput name="subaction" value="unlock" />
          <Button
            disabled={unlockRequested}
            variant="primary"
            className={clsx('h-fit w-fit !border-neutral-350 !shadow-none')}
            Icon={unlockRequested ? CheckCircleIcon : RocketLaunchIcon}
            iconClassName="text-neutral-00"
            type="submit"
          >
            {unlockRequested ? 'Upgrade requested' : 'Get Build for free'}
          </Button>
        </ValidatedForm>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col gap-8">
      {syncStatus !== 'ongoing' && (
        <div className="flex w-full gap-6">
          {/* <div
            className={clsx('flex max-w-[50%] gap-2 rounded-sm border p-2', {
              'border-[#DCB656] bg-[#FFF1D7]': syncStatus === 'success',
              'border-secondary-400 bg-[#FFE4E9]': syncStatus === 'failed',
            })}
          >
            <InformationCircleIcon className="max-h-[16px] min-h-[16px] min-w-[16px] max-w-[16px] text-neutral-450" />
            <Text className="!text-neutral-450">
              {statusTitle[syncStatus].description}
            </Text>
          </div> */}
          <div className="flex w-fit items-end gap-6">
            {syncStatus === 'success' && (
              <>
                {!startDate ? (
                  <>
                    <Link to={`/tools/${toolId}/general`}>
                      <Button
                        variant="primary"
                        className={clsx(
                          'h-fit w-fit !border-neutral-200 !shadow-none',
                        )}
                      >
                        Start the trial
                      </Button>
                    </Link>
                  </>
                ) : (
                  <>
                    <Link to={`/tools/${toolId}/general`}>
                      <Button
                        variant="primary"
                        className={clsx(
                          'h-fit w-fit !border-neutral-200 !shadow-none',
                        )}
                      >
                        Go to your widget
                      </Button>
                    </Link>

                    <ValidatedForm
                      validator={unlockProductValidator}
                      defaultValues={{ subaction: 'unlock' }}
                      action="/action/trial"
                      method="post"
                    >
                      <HiddenInput name="subaction" value="unlock" />
                      <Button
                        disabled={unlockRequested}
                        variant="secondary"
                        className={clsx(
                          'h-fit w-fit !border-neutral-550 !shadow-none',
                        )}
                        Icon={unlockRequested ? CheckCircleIcon : undefined}
                        iconClassName="text-neutral-00"
                        type="submit"
                      >
                        {unlockRequested
                          ? 'Upgrade requested'
                          : 'Unlock the full product'}
                      </Button>
                    </ValidatedForm>
                  </>
                )}
              </>
            )}

            {syncStatus === 'failed' && (
              <a href="mailto:hello@raffle.ai" target="_blank" rel="noreferrer">
                <Button
                  variant="secondary"
                  className={clsx(
                    'h-fit w-fit !border-neutral-550 !shadow-none',
                  )}
                >
                  Contact Support
                </Button>
              </a>
            )}
          </div>
        </div>
      )}

      <div className="flex w-full flex-col gap-2">
        <Text className="leading-3 !text-neutral-550" size="sm">
          {statusTitle[syncStatus].title}
        </Text>
        <div className="mt-1 flex w-full rounded-md border border-neutral-350 md:flex-col lg:flex-row">
          {statusSteps.map((step, index) => (
            <div
              key={`status-step-${index}`}
              className="flex flex-1 gap-2 border-neutral-350 bg-neutral-100 p-3 align-middle last:border-none md:border-b md:first:rounded-none md:last:rounded-none lg:border-b-0 lg:border-r lg:first:rounded-l-md lg:last:rounded-r-md"
            >
              <div
                className={clsx(
                  'max-h-[1.25rem] min-h-[1.25rem] min-w-[1.25rem] max-w-[1.25rem] flex-1 rounded-full border border-neutral-350',
                  {
                    'text-accent-green-400':
                      index <= 1 || syncStatus === 'success',
                    'text-secondary-400': syncStatus === 'failed' && index > 1,
                  },
                )}
              >
                {(syncStatus === 'success' || index <= 1) && (
                  <CheckCircleIcon />
                )}
                {syncStatus === 'failed' && index > 1 && <XCircleIcon />}
              </div>
              <Text className="!text-neutral-550">{step}</Text>
            </div>
          ))}
        </div>
        <Text className="!text-neutral-450" size="sm">
          {statusTitle[syncStatus].subtitle}{' '}
          {syncStatus === 'success' && (
            <span className="text-neutral-450">
              {startDate
                ? `${days} days ${hours} hours ${minutes} minutes`
                : 'Trial not started'}
            </span>
          )}
        </Text>
      </div>
    </div>
  );
};

const trialStatusIcon: Record<TrialStatusProps['syncStatus'], React.ReactNode> =
  {
    ongoing: <CloudArrowDownIcon className="h-8 w-8 text-accent-green-400" />,
    success: (
      <ShieldExclamationIcon className="h-8 w-8 text-accent-green-400" />
    ),
    failed: <ExclamationTriangleIcon className="h-8 w-8 text-[#DCB656]" />,
  };

export const TrialStatus = ({
  syncStatus,
}: Pick<TrialStatusProps, 'syncStatus'>) => {
  const fetcher = useFetcher();

  return (
    <div className="flex h-full flex-col justify-center gap-8 px-20">
      <div className="flex flex-col items-center gap-3">
        {trialStatusIcon[syncStatus]}
        <Text
          className="leading-[150%] !text-neutral-600"
          size="lg"
          weight="semibold"
        >
          {statusTitle[syncStatus].title}
        </Text>
        {syncStatus === 'ongoing' && (
          <Text className="text-center">
            {statusTitle[syncStatus].description}
          </Text>
        )}
        {/* {syncStatus !== 'ongoing' && (
          <div
            className={clsx('flex w-full gap-2 rounded-sm border p-2', {
              'border-[#DCB656] bg-[#FFF1D7]': syncStatus === 'success',
              'border-secondary-400 bg-secondary-50/50':
                syncStatus === 'failed',
            })}
          >
            <InformationCircleIcon className="max-h-[16px] min-h-[16px] min-w-[16px] max-w-[16px] text-neutral-450" />
            <Text className="!text-neutral-450">
              {statusTitle[syncStatus].description}
            </Text>
          </div>
        )} */}
      </div>

      <div className="flex w-full flex-col rounded-sm border border-neutral-250">
        {statusSteps.map((step, index) => (
          <div
            key={`status-step-${index}`}
            className="flex flex-1 items-center justify-between gap-2 border-b border-neutral-250 bg-neutral-0 p-3 first:rounded-t-sm last:rounded-b-sm last:border-none"
          >
            <div className="flex gap-2">
              <div
                className={clsx(
                  'max-h-[1.25rem] min-h-[1.25rem] min-w-[1.25rem] max-w-[1.25rem] flex-1 rounded-full border border-neutral-350',
                  {
                    'text-accent-green-400':
                      index <= 1 || syncStatus === 'success',
                    'text-secondary-400': syncStatus === 'failed' && index > 1,
                  },
                )}
              >
                {(syncStatus === 'success' || index <= 1) && (
                  <CheckCircleIcon />
                )}
                {syncStatus === 'failed' && index > 1 && <XCircleIcon />}
              </div>
              <Text className="!text-neutral-600">{step}</Text>
            </div>
            <div>
              {index < 2 && (
                <Text size="sm" className="text-accent-green-400">
                  Done!
                </Text>
              )}
              {index >= 2 && (
                <Text
                  size="sm"
                  className={clsx({
                    '!text-accent-green-400': syncStatus === 'success',
                    '!text-secondary-400': syncStatus === 'failed',
                    '!text-neutral-450': syncStatus === 'ongoing',
                  })}
                >
                  {syncStatus === 'failed' && 'Failed'}
                  {syncStatus === 'success' && 'Done!'}
                  {syncStatus === 'ongoing' &&
                    (index === 2 ? 'Ongoing...' : 'Not started')}
                </Text>
              )}
            </div>
          </div>
        ))}
      </div>

      {syncStatus === 'success' && (
        <Button
          variant="primary"
          className="w-full"
          onClick={() =>
            fetcher.submit(
              { subaction: 'start' },
              { method: 'post', action: '/action/trial' },
            )
          }
        >
          Start your 7 days trial now →
        </Button>
      )}

      {syncStatus === 'failed' && (
        <a
          href="mailto:hello@raffle.ai"
          target="_blank"
          rel="noreferrer"
          className="w-full"
        >
          <Button variant="primary" className="w-full">
            Contact Support →
          </Button>
        </a>
      )}
    </div>
  );
};

export const ToolTrialExpired = () => (
  <div className="flex h-full flex-col justify-center gap-8 px-44">
    <div className="flex flex-col items-center gap-3">
      <ExclamationTriangleIcon className="h-8 w-8 text-[#DCB656]" />

      <Text
        className="leading-[150%] !text-neutral-600"
        size="lg"
        weight="semibold"
      >
        Your trial has expired
      </Text>
      <Text className="text-center">
        Thank you for trying Raffle! We hope you had a great experience with our
        product. If you are interested in learning more, please{' '}
        <a
          href="mailto:hello@raffle.ai"
          target="_blank"
          rel="noreferrer"
          className="w-full text-accent-purple-300 underline"
        >
          click here.
        </a>
      </Text>
    </div>
    <div className="flex flex-col items-center gap-3">
      <UnlockProductButton />
      <a
        href="mailto:hello@raffle.ai"
        target="_blank"
        rel="noreferrer"
        className="w-full"
      >
        <Button className="w-full">Contact Support</Button>
      </a>
    </div>
  </div>
);

export default TrialStatus;
