import React, { useState } from 'react';

import { useOutletContext } from '@remix-run/react';

import clsx from 'clsx';
import _get from 'lodash.get';
import { AnimatePresence, motion } from 'framer-motion';

import {
  QuestionMarkCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/solid';
import { StarIcon } from '@heroicons/react/24/outline';
import {
  ChevronUpIcon,
  ChevronDownIcon as SmallChevronDownIcon,
} from '@heroicons/react/20/solid';

import type { OutletContext } from '~/routes/_app.tools_.$toolId/view';

import type { HeroIcon } from '~/types';

import { growHeightFadeIn, type SearchInstanceObjectPath } from '~/utils';

import { BorderCard, Card } from '../Layout';
import { Text } from '../_legacy/Typography';
import { Checkbox } from '../Form';
import Button from '../Button';
import DynamicTooltip from '../DynamicTooltip';
import Chip from '../Chip';

type CategoryCardProps = {
  className?: string;
  children: React.ReactNode;
};

export const CategoryCard = ({ children, className }: CategoryCardProps) => (
  <Card
    className={clsx(
      'flex flex-col gap-4 !rounded-sm !bg-neutral-150',
      className,
    )}
  >
    {children}
  </Card>
);

type CategoryTitleProps = {
  title: string;
  additionalContent?: React.ReactNode;
};
export const CategoryTitle = ({
  title,
  additionalContent,
}: CategoryTitleProps) => (
  <div className="flex items-center gap-2">
    <Text
      weight="semibold"
      className="!text-lg leading-normal"
      variant="heading"
    >
      {title}
    </Text>
    {additionalContent}
  </div>
);

type CategoryProps = {
  title?: string;
  description?: string;
  className?: string;
  collapsable?: boolean;
  children: React.ReactNode;
  titleAdditionalContent?: React.ReactNode;
  defaultExpanded?: boolean;
  childrenWrapperClassName?: string;
};

export const Category = ({
  title,
  description,
  children,
  className,
  collapsable = false,
  defaultExpanded = false,
  titleAdditionalContent,
  childrenWrapperClassName,
}: CategoryProps) => {
  const [isExpanded, setIsExpanded] = useState(!collapsable || defaultExpanded);

  return (
    <div
      className={clsx(
        'flex w-full flex-col border-b border-b-neutral-250 transition-all',
        className,
      )}
    >
      {(title || collapsable) && (
        <div
          className={clsx(
            'flex items-center justify-between gap-2 p-6 transition-all duration-300',
            {
              'cursor-pointer': collapsable,
            },
          )}
          onClick={collapsable ? () => setIsExpanded(!isExpanded) : undefined}
        >
          {title && (
            <div className="flex flex-col justify-center gap-1">
              <CategoryTitle
                title={title}
                additionalContent={titleAdditionalContent}
              />
              {description && (
                <Text className="leading-[150%]">{description}</Text>
              )}
            </div>
          )}
          {collapsable && (
            <div className="flex items-center justify-center">
              <Button
                className="!p-2"
                variant="icon"
                Icon={isExpanded ? ChevronUpIcon : SmallChevronDownIcon}
                iconClassName="w-3 h-3"
              />
            </div>
          )}
        </div>
      )}

      <AnimatePresence mode="wait">
        {isExpanded && (
          <motion.div {...growHeightFadeIn}>
            <div
              className={clsx(
                'flex flex-col gap-4 p-6',
                childrenWrapperClassName,
                { 'pt-0': title || collapsable },
              )}
            >
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

type OptionCardProps = {
  className?: string;
  internal?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
};
export const OptionCard = ({
  children,
  className,
  internal = false,
  disabled = false,
}: OptionCardProps) => (
  <BorderCard
    className={clsx(
      '!pointer-events-auto flex flex-col !rounded-sm border !border-neutral-250 shadow-drop',
      { '!bg-white-off': internal },
      { 'opacity-50': disabled },
      className,
    )}
  >
    {children}
  </BorderCard>
);

type ExpandableToggleCardProps = {
  Icon?: HeroIcon;
  label: string;
  name: SearchInstanceObjectPath;
  description?: string;
  tooltipInfo?: string;
  toggleLabel?: string;
  toggleDescription?: string;
  className?: string;
  reverse?: boolean;
  disabled?: boolean;
  purchasable?: boolean;
  isNew?: boolean;
  onExpand?: (val: boolean) => void;
  children?: React.ReactNode;
  expanded?: boolean;
};

export const ExpandableToggleCard = ({
  Icon,
  label,
  name,
  description,
  tooltipInfo,
  toggleLabel = 'Enable',
  toggleDescription,
  children,
  reverse = false,
  disabled = false,
  purchasable = false,
  isNew = false,
  onExpand,
  expanded = false,
}: ExpandableToggleCardProps) => {
  const { tool, onChangeTool } = useOutletContext<OutletContext>();

  const value = Boolean(_get(tool, name, false));
  const isChecked = reverse ? !value : value;
  const checkboxId = `checkbox-${name}`;

  const [isExpanded, setIsExpanded] = useState(expanded);

  const handleToggle = () => {
    const newValue = reverse ? isChecked : !isChecked;
    onChangeTool({ [name]: newValue });
    if (onExpand) {
      onExpand(!isChecked);
    }
  };

  const handleExpand = () => {
    if (onExpand) {
      onExpand(!isExpanded);
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <OptionCard className="!p-0">
      <div
        className={clsx(
          'flex flex-grow cursor-pointer items-center justify-between gap-2 rounded-t-sm border-neutral-250 bg-neutral-100 p-3 transition-all',
          {
            'border-b': isExpanded,
            'rounded-b-sm': !isExpanded,
          },
        )}
        onClick={handleExpand}
      >
        <div className="flex items-center gap-3">
          {Icon && (
            <div
              className={clsx(
                'flex items-center justify-center rounded-sm border border-neutral-250 bg-white p-4 transition-all',
                { '!bg-primary-600': isChecked },
              )}
            >
              <Icon
                className={clsx('h-4 w-4 text-neutral-600 transition-all', {
                  '!text-white': isChecked,
                })}
              />
            </div>
          )}

          <div className="flex flex-col">
            <div className="flex items-center gap-1">
              <Text
                weight="semibold"
                variant="heading"
                className="leading-[150%]"
              >
                {label}
              </Text>
              {tooltipInfo && (
                <QuestionTooltip>
                  <Text>{tooltipInfo}</Text>
                </QuestionTooltip>
              )}
            </div>
            <Text className="leading-[150%]">{description}</Text>
          </div>

          {disabled && purchasable && <UpgradeTooltip />}
        </div>

        <div className="flex items-center gap-2">
          {isNew && (
            <Text
              size="sm"
              className="rounded-sm bg-accent-purple-400 px-1 py-0.5 !text-white"
            >
              New
            </Text>
          )}

          <Chip
            className={clsx('!border-neutral-250 transition-all', {
              '!border-accent-green-100 !bg-accent-green-50/40': isChecked,
            })}
            textClassName={clsx('', {
              '!text-accent-green-500': isChecked,
            })}
          >
            {isChecked ? 'Enabled' : 'Disabled'}
          </Chip>

          <Button
            Icon={isExpanded ? ChevronDownIcon : ChevronRightIcon}
            variant="none"
            className="text-neutral-450 transition-all"
          />
        </div>
      </div>

      <AnimatePresence mode="wait">
        {isExpanded && (
          <motion.div
            {...growHeightFadeIn}
            className="rounded-b-sm bg-neutral-50"
          >
            <div
              className={clsx(
                'flex items-center justify-between border-neutral-250 p-4',
                {
                  'border-b': children,
                },
              )}
            >
              <div className="flex flex-col">
                <Text
                  weight="semibold"
                  variant="heading"
                  className="leading-[150%]"
                >
                  {toggleLabel}
                </Text>
                <Text className="leading-[150%]">{toggleDescription}</Text>
              </div>
              <label htmlFor={checkboxId}>
                <Checkbox
                  ref={undefined}
                  name={checkboxId}
                  id={checkboxId}
                  checked={isChecked}
                  onChange={handleToggle}
                  disabled={disabled}
                />
              </label>
            </div>

            {children && (
              <div className="flex flex-col gap-4 p-4">{children}</div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </OptionCard>
  );
};

type ToggleOptionProps = {
  Icon?: HeroIcon;
  label: string;
  name: SearchInstanceObjectPath;
  description?: string;
  tooltipInfo?: string;
  className?: string;
  checkToExpand?: boolean;
  hideExpand?: boolean;
  internal?: boolean;
  reverse?: boolean;
  expanded?: boolean;
  disabled?: boolean;
  purchasable?: boolean;
  isNew?: boolean;
  onExpand?: (val: boolean) => void;
  children?: React.ReactNode;
};
export const ToggleOption = ({
  Icon,
  label,
  name,
  description,
  tooltipInfo,
  className,
  internal,
  reverse = false,
  disabled = false,
  purchasable = false,
  isNew = false,
}: ToggleOptionProps) => {
  const { tool, onChangeTool } = useOutletContext<OutletContext>();

  const value = Boolean(_get(tool, name, false));
  const isChecked = reverse ? !value : value;
  const checkboxId = `checkbox-${name}`;

  const handleToggle = () => {
    const newValue = reverse ? isChecked : !isChecked;
    onChangeTool({ [name]: newValue });
  };

  return (
    <OptionCard
      className={clsx('!pl-3', className)}
      internal={internal}
      disabled={disabled}
    >
      <div className="flex flex-grow items-center justify-between gap-2">
        <div className="flex items-center gap-3">
          {Icon && (
            <div
              className={clsx(
                'flex items-center justify-center rounded-sm border border-neutral-250 bg-white p-4',
                { '!bg-primary-600': isChecked },
              )}
            >
              <Icon
                className={clsx('h-4 w-4 text-neutral-600', {
                  '!text-white': isChecked,
                })}
              />
            </div>
          )}
          <div className="flex flex-col">
            <div className="flex items-center gap-1">
              <Text
                weight="semibold"
                variant="heading"
                className="leading-[150%]"
              >
                {label}
              </Text>
              {tooltipInfo && (
                <QuestionTooltip>
                  <Text>{tooltipInfo}</Text>
                </QuestionTooltip>
              )}
            </div>
            <Text className="leading-[150%]">{description}</Text>
          </div>

          {disabled && purchasable && <UpgradeTooltip />}
        </div>

        <div className="flex items-center gap-3">
          {isNew && (
            <Text
              size="sm"
              className="rounded-sm bg-accent-purple-400 px-1 py-0.5 !text-white"
            >
              New
            </Text>
          )}

          <label htmlFor={checkboxId}>
            <Checkbox
              ref={undefined}
              name={checkboxId}
              id={checkboxId}
              checked={isChecked}
              onChange={handleToggle}
              disabled={disabled}
            />
          </label>
        </div>
      </div>
    </OptionCard>
  );
};

type QuestionTooltipProps = {
  children: React.ReactElement;
};
export const QuestionTooltip = ({ children }: QuestionTooltipProps) => (
  <DynamicTooltip offset={{ x: 0, y: -30 }} content={children}>
    <QuestionMarkCircleIcon className="h-4 w-4 text-grey" />
  </DynamicTooltip>
);

const UpgradeTooltip = () => (
  <DynamicTooltip
    offset={{ x: 10, y: -50 }}
    content={<Text>Upgrade plan</Text>}
  >
    <StarIcon className="h-5 w-5 text-accent-purple-400" />
  </DynamicTooltip>
);
