import { useState } from 'react';

import { v4 as uuid } from 'uuid';
import { useField } from 'remix-validated-form';

import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid';

import Button from '../Button';
import { HiddenInput, ValidatedInput } from './Input';
import { useFieldDiffSetter } from './FieldDiffProvider';

export type ArrayInputProps = JSX.IntrinsicElements['input'] & {
  defaultValue?: string[];
};

type ArrayInputValue = {
  id: string;
  value: string;
  maxLength?: number;
};

export const ValidatedArrayInput = ({
  name,
  defaultValue = [],
  maxLength,
}: ArrayInputProps) => {
  const setFormDiff = useFieldDiffSetter();

  const [arrayValues, setArrayValues] = useState<ArrayInputValue[]>(() =>
    defaultValue?.length > 0
      ? defaultValue.map((val) => ({ id: uuid(), value: val }))
      : [{ id: uuid(), value: '' }],
  );

  const maxLengthReached = maxLength === arrayValues.length;

  return (
    <>
      {arrayValues.map(({ id, value }, index) => {
        const isLast = index === arrayValues.length - 1;
        return (
          <div key={id} className="flex items-center justify-between gap-2">
            <ValidatedInput
              name={name}
              defaultValue={value}
              onChange={(e) => {
                const newValue = e.target.value;
                const updatedArrayValues = [...arrayValues];
                updatedArrayValues[index] = {
                  id,
                  value: newValue,
                };

                setArrayValues(updatedArrayValues);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore - we know that name is present
                setFormDiff(name, defaultValue[index] !== newValue);
                e.stopPropagation();
              }}
              containerClassName="w-full"
            />
            {isLast && !maxLengthReached ? (
              <Button
                disabled={!value.length}
                variant="icon"
                Icon={PlusIcon}
                onClick={() => {
                  setArrayValues((prev) => [
                    ...prev,
                    { id: uuid(), value: '' },
                  ]);
                }}
              />
            ) : (
              <Button
                Icon={TrashIcon}
                variant="icon"
                iconClassName="text-secondary-300"
                onClick={() => {
                  setArrayValues((prev) =>
                    prev.filter((item) => item.id !== id),
                  );
                }}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

export const HiddenArrayInput = ({
  name,
  value,
}: {
  name: string;
  value?: string[];
}) => {
  const { defaultValue } = useField(name);

  return (
    <>
      {value
        ? value.map((val: string, index: number) => (
            <HiddenInput key={index} name={`${name}[${index}]`} value={val} />
          ))
        : defaultValue.map((_: string, index: number) => (
            <HiddenInput key={index} name={`${name}[${index}]`} />
          ))}
    </>
  );
};
