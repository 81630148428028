import clsx from 'clsx';

import { CheckCircleIcon, ShieldCheckIcon } from '@heroicons/react/24/solid';

import type { GroupBase } from 'react-select';
import type { FieldProps } from '../Form';

import type { WidgetTemplate } from '~/models';

import { type SearchInstanceObjectPath, widgetTemplates } from '~/utils';

import { Field } from '../Form';
import { Text } from '../_legacy/Typography';

import overlayImgUrl from '/public/assets/tools/overlay.svg';
import inlineImgUrl from '/public/assets/tools/inline.svg';
import embeddedImgUrl from '/public/assets/tools/embedded.svg';
import launcherImgUrl from '/public/assets/tools/launcher.svg';

export const ToolField = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  name,
  adminOnly = false,
  label,
  description,
  noCard = false,
  ...props
}: FieldProps<Option, IsMulti, Group> & {
  name: SearchInstanceObjectPath;
  adminOnly?: boolean;
  noCard?: boolean;
}) => (
  <div
    className={clsx('flex w-full flex-col', {
      'rounded-sm border border-neutral-250 shadow-drop': !noCard,
    })}
  >
    {!noCard && (
      <div className="flex flex-col rounded-t-sm border-b border-neutral-250 bg-neutral-100 px-4 py-3">
        <Text weight="semibold" variant="heading" className="leading-[150%]">
          {label}
        </Text>
        <Text className="leading-[150%]">{description}</Text>
      </div>
    )}
    <div className={clsx({ 'p-4': !noCard })}>
      <Field
        name={name}
        {...props}
        {...(noCard ? { label } : { label: '' })}
        {...(noCard ? { description } : { description: '' })}
        {...(adminOnly && { LabelIcon: ShieldCheckIcon })}
      />
    </div>
  </div>
);

const toolTemplateImgDict = {
  embedded: embeddedImgUrl,
  overlay: overlayImgUrl,
  inline: inlineImgUrl,
  launcher: launcherImgUrl,
};

type TemplateFieldProps = {
  template: WidgetTemplate;
  onSelectTemplate: (template: WidgetTemplate) => void;
};
export const TemplateField = ({
  template,
  onSelectTemplate,
}: TemplateFieldProps) => (
  <div className="flex flex-col justify-between gap-4">
    <div className="flex flex-col gap-1">
      <Text weight="semibold" size="md" variant="heading">
        Choose Search UI type
      </Text>
      <Text className="text-neutral-500">
        Tailor your search tool to match your needs
      </Text>
    </div>

    <div className="flex justify-between gap-6">
      {widgetTemplates.map((toolTemplate) => (
        <ToolTemplateCard
          key={toolTemplate.id}
          onClick={() => onSelectTemplate(toolTemplate.id)}
          selected={template === toolTemplate.id}
          title={toolTemplate.title}
          imgUrl={toolTemplateImgDict[toolTemplate.id]}
        />
      ))}
    </div>
  </div>
);

type ToolTemplateCardProps = {
  title: string;
  imgUrl: string;
  selected?: boolean;
  onClick: () => void;
};

const ToolTemplateCard = ({
  imgUrl,
  title,
  selected = false,
  onClick,
}: ToolTemplateCardProps) => (
  <button
    onClick={onClick}
    className="relative flex cursor-pointer flex-col gap-2 p-1"
    type="button"
  >
    <img className="w-full" src={imgUrl} alt={`template-${title}`} />

    <div className="flex flex-col justify-evenly">
      <Text
        weight="medium"
        className={clsx({ 'text-accent-purple-400': selected })}
      >
        {title}
      </Text>

      {selected && (
        <CheckCircleIcon className="absolute -right-1.5 -top-2 h-5 w-5 text-accent-purple-400" />
      )}
    </div>
  </button>
);
