import clsx from 'clsx';

export type CardProps = {
  className?: string;
} & JSX.IntrinsicElements['div'];

const Card = ({ children, className, ...rest }: CardProps) => (
  <div
    className={clsx(
      'rounded-lg border border-neutral-250 bg-neutral-50 p-4 shadow-drop',
      className,
    )}
    {...rest}
  >
    {children}
  </div>
);

export default Card;
